import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const FaqItem = ({ question, isOpen, toggleFunction, content }) => {
  return (
    <div className="border-dotted border-t-2 py-4 flex flex-col w-full">
      <button onClick={toggleFunction} className="font-lexend text-[16px] text-primary-p60 font-bold flex flex-row justify-between text-left">
        <h1>{question}</h1>
        <span className="">{isOpen ? 'v' : '^'}</span>
      </button>
      {isOpen && (
        <div className="font-lexend text-[16px] text-primary-p60 leading-7 py-8">
          {content}
        </div>
      )}
    </div>
  );
};

const DropdownFAQ = () => {
  const location = useLocation();
  const [faqs, setFaqs] = useState([
    {
      question: 'Bagaimana proses belajarnya?',
      isOpen: false,
      content: 'Proses belajar akan dilangsungkan secara online menggunakan aplikasi Zoom. Jadi pastikan gadget-mu sudah memiliki aplikasi Zoom pada saat pembelajaran dimulai ya! Tertarik untuk mengikuti kelas Offline? Yuk chat TokTok Class via WhatsApp.',
    },
    {
      question: 'Berapa biaya yang dibutuhkan?',
      isOpen: false,
      content: 'Biaya yang dibutuhkan bergantung pada jenis dan tingkatan kelas yang Murid pilih. Untuk info lengkap tentang biaya, silakan chat WA / cek Instagram @toktokclass ya! Pembayaran dilakukan diawal untuk mengikuti pembelajaran selama 1 batch. 1 batch terdiri dari 1 tingkatan kelas, dengan total 8 pertemuan selama 4 minggu.',
    },
    {
      question: 'Apakah saya bisa memilih jadwal belajar yang saya inginkan?',
      isOpen: false,
      content: 'Tentu bisa! Tipe kelas Privat, Murid bisa menentukan jadwal belajar yang diinginkan. Tipe kelas Grup, Murid bisa memilih dari pilihan jadwal yang tersedia. Untuk mengetahui pilihan jadwal Grup, silakan chat TokTok Class via WhatsApp / Instagram @toktokclass.',
    },
    {
      question: 'Buku apa yang dipakai selama proses belajar?',
      isOpen: false,
      content: 'TokTok Class akan memberikan e-book secara gratis kepada Murid sesuai dengan tingkatan kelas yang dipilih. E-book berisi materi-materi yang dibuat khusus oleh guru-guru di TokTok Class, yang tentunya sudah disesuaikan dengan kebutuhan dan perkembangan Bahasa Korea masa kini.',
    },
    {
      question: 'Kalau saya sudah belajar Bahasa Korea sebelumnya, apakah bisa langsung belajar di tingkatan kelas tertentu?',
      isOpen: false,
      content: 'Tentu bisa! Silakan cek silabus tingkatan kelas disini (link tingkatan kelas). Murid bisa tentukan sudah menguasai silabus sampai di tingkatan apa, dan naik 1 tingkatan untuk belajar bersama di TokTok Class.',
    },
    {
      question: 'Berapa jumlah Murid per kelas?',
      isOpen: false,
      content: 'Jumlah Murid bergantung pada jenis dan tingkatan kelas yang Murid pilih. Jenis privat terdiri dari 1-2 Murid / kelas. Jenis grup terdiri dari 6-10 Murid / kelas (Hangeul - Tata Bahasa 4) dan 5-8 Murid (Tata Bahasa 5 - Conversation).',
    },
  ]);

  const maxFaqsToShow = location.pathname === "/" ? 3 : faqs.length;

  const toggleFaq = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => {
        if (i === index) {
          return { ...faq, isOpen: !faq.isOpen };
        }
        return faq;
      })
    );
  };

  return (
    <div className={`flex flex-col items-center py-8 mx-auto ${location.pathname === "/" ? "w-9/12" : ""}`}>
      {faqs.slice(0, maxFaqsToShow).map((faq, index) => (
        <FaqItem
          key={index}
          question={faq.question}
          isOpen={faq.isOpen}
          toggleFunction={() => toggleFaq(index)}
          content={faq.content}
        />
      ))}
    </div>
  );
};


export default DropdownFAQ;