import React from "react";

import Ig from '../assets/logo_ig.png';
import Tt from '../assets/logo_tt.png';
import Wa from '../assets/logo_wa.png';
import Yt from '../assets/logo_yt.png';

const Footbar = () => {
    return (
        <div className="bg-primary-p60 py-8">
            <div className="flex flex-col desktop:flex-row justify-between w-10/12 mx-auto px-4 py-6">

                <div className="flex flex-col space-y-4 desktop:pl-4">
                    <div>
                        <h1 className="font-lexend text-base font-normal leading-5 text-neutral-white">Informasi lebih lanjut follow <br/> TokTok Class di:</h1>
                    </div>
                   
                    <div className="items-center space-x-4">
                        <div className="relative inline-block p-2 bg-neutral-white rounded-full">
                            <a href="https://www.youtube.com/@BORASSAEM" className="block w-4 h-4">
                                <img src={Yt} alt="Youtube" className="w-full h-full" />
                            </a>
                        </div>
                        <div className="relative inline-block p-2 bg-neutral-white rounded-full">
                            <a href="https://www.instagram.com/toktokclass/" className="block w-4 h-4">
                                <img src={Ig} alt="Instagram" className="w-full h-full" />
                            </a>
                        </div>
                        <div className="relative inline-block p-2 bg-neutral-white rounded-full">
                            <a href="https://www.tiktok.com/@borassaem" className="block w-4 h-4">
                                <img src={Tt} alt="Tiktok" className="w-full h-full" />
                            </a>
                        </div>
                        <div className="relative inline-block p-2 bg-neutral-white rounded-full">
                            <a href="https://wa.me/+6285714549374" className="block w-4 h-4">
                                <img src={Wa} alt="WhatsApp" className="w-full h-full" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 desktop:grid-cols-3 gap-16 gap-y-8 pt-8 desktop:pt-0">
                    <div className="font-lexend text-base leading-none text-neutral-white flex flex-col space-y-2">
                        <a  className="font-bold">Tentang kami</a>
                        <a href="/tentangKami/tokTokClass" className="font-extralight">TokTok class</a>
                        <a href="/tentangKami/staffPengajar" className="font-extralight">Staff pengajar</a>
                        <a href="/tentangKami/bukuBorassaem" className="font-extralight">Buku Borassaem</a>
                    </div>

                    <div className="font-lexend text-base leading-none text-neutral-white flex flex-col space-y-2">
                        <a className="font-bold">Kelas</a>
                        <a href="/kelas/jenisKelas" className="font-extralight">Jenis kelas</a>
                        <a href="/kelas/tingkatanKelas" className="font-extralight">Tingkatan kelas</a>
                    </div>

                    <a href="/testimoni" className="font-lexend text-base leading-none text-neutral-white font-bold">Testimoni</a>
                    <a href="/daftar" className="font-lexend text-base leading-none text-neutral-white font-bold">Daftar sekarang</a>
                    <a href="/mediaSosial" className="font-lexend text-base leading-none text-neutral-white font-bold">Social media</a>
                    <a href="/faq" className="font-lexend text-base leading-none text-neutral-white font-bold">FAQ</a>
                </div> 

            </div>

            <div className="flex justify-center">
                <h1 className="font-lexend text-neutral-white leading-5 border-t-2 pt-8 w-[80%]">
                    2023 TokTok Class - All rights reserved
                </h1>
            </div>
        </div>
    )
}

export default Footbar;