import React from 'react';
import Ig from '../assets/logo_ig.png';
import Tt from '../assets/logo_tt.png';
import Wa from '../assets/logo_wa.png';
import Yt from '../assets/logo_yt.png';

const FooterNavBar = () => {

    const socialMediaPlatforms = [
        { name: 'Youtube', icon: Yt, link:'https://www.youtube.com/@BORASSAEM' },
        { name: 'Instagram', icon: Ig, link:'https://www.instagram.com/toktokclass/' },
        { name: 'Tiktok', icon: Tt, link:'https://www.tiktok.com/@borassaem' },
        { name: 'WhatsApp', icon: Wa, link:'https://wa.me/+6285714549374' }
      ];

    return (
        <div className="justify-center mb-4">
        <p className="text-center font-lexend text-xs text-primary-p50 mb-4">Informasi lebih lanjut follow <br/> TokTok Class di:</p>
        <div className="flex justify-center items-center space-x-4">
            {socialMediaPlatforms.map((platform, index) => (
                <div key={index} className="relative inline-block p-2 bg-neutral-white rounded-full">
                    <a href={platform.link} className="block w-4 h-4 hover:scale-150 transition-transform">
                        <img src={platform.icon} alt={platform.name} className="w-full h-full hover:scale-150 transition-transform" />
                    </a>
                </div>
            ))}
        </div>
    </div>
    )
}

export default FooterNavBar;