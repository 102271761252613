import React from "react";
import DropdownFAQ from "../components/DropdownFAQ";
import Footbar from "../components/Footbar";
//assets 
import Guy from '../assets/faq_guy.png';

const Faq = () => {
    return (
        <div >
            <div className="flex flex-col desktop:flex-row items-center justify-center desktop:justify-between">
                <img className="py-8 object-fit w-[64vw] desktop:w-[28vw] desktop2k:w-[36vw]" src={Guy} alt="hero_image" />
                <div className="flex flex-col justify-around py-4 w-9/12 desktop:w-3/5">
                    <h1 className="font-jua text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title text-primary-p60 text-center desktop:text-left">FAQs</h1>
                    <h2 className="font-jua text-[16px] desktop:text-section-title text-primary-p60 text-center desktop:text-left">(Pertanyaan yang sering ditanyakan)</h2>
                    <h2 className="font-jua text-[24] text-primary-p60 desktop:w-10/12 pt-8 text-center desktop:text-left">Halaman ini dikhususkan untuk menjawab pertanyaan-pertanyaan yang sering muncul di benak mu, terutama bagi yang baru pertama kali kenal dengan TokTok Class. Silahkan dibaca dulu, jika ada pertanyaan lain yang belum ada di sini atau kurang jelas jangan ragu untuk klik tombol “Mulai Percakapan” di bawah, dengan senang hati kami akan menjawab pertanyaanmu.</h2>
                </div>
            </div>

            <div className="w-9/12 desktop:w-10/12 mx-auto px-0 desktop:px-4">
                <DropdownFAQ/>
            </div>

            <Footbar/>
            
        </div>
    )
}

export default Faq;