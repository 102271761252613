import React, { useEffect, useState } from "react";

//assets 
import Bukubuku from '../assets/buku_buku.png';
import BukubukuMid from '../assets/buku_buku_mid.png';
import Footbar from "../components/Footbar";
import BookCarousel from "../components/BookCarousel";

import Book1 from '../assets/book_dear_oppa.png';
import Book2 from '../assets/book_fantastic_korean.png';
import Book3 from '../assets/book_hello_korean.png';


const BukuBorassaem = () => {
    const books = [
        {
            title: "Dear Oppa",
            image: Book1,
            description: "Para pecinta Kpop, yang saking sukanya sama K-Pop Idol, pernah nggak sih ingin nulis surat untuk bias tersayang? Apalagi kalau pakai Bahasa Korea, pasti kemungkinan untuk di-notice lebih besar, kan? Nggak usah takut buat mulai belajar! Buku ini akan membantumu untuk mengungkapkan terima kasih, permintaan maaf, dukungan, dan harapanmu pada bias-mu. Dengan buku yang full colour dan banyaknya ilustrasi lucu, teman-teman akan merasa dengan buku ini, belajar Bahasa Korea adalah sebuah aktivitas yang sangat menyenangkan!",
            link: "https://shopee.co.id/Buku-Pelajaran-Bahasa-Korea-Dear-Oppa-i.37345149.1957566489?sp_atk=bf2351e6-2968-4aa9-886d-d929266c5a85&xptdk=bf2351e6-2968-4aa9-886d-d929266c5a85"
        },
        {
            title: "Funtastic Korean",
            image: Book2,
            description: "Belajar Bahasa asing itu paling mudah dengan mempelajari Bahasa sehari-hari yang pasti digunakan, iya kan? Jika kamu ingin belajar Bahasa Korea dengan mudah, kali ini Borassaem mengajak kalian untuk memperkenalkan diri, jalan-jalan ke Korea, makan bersama gebetan, bahkan nonton konser bareng teman dengan Bahasa Korea! Funtastic Korean terdiri dari: dasar-dasar huruf Korea (Hangeul) dan penjelasannya, contoh percakapan sehari-hari dalam hangul, romanji, dan Bahasa Indonesia, serta tata Bahasa Korea dasar dan latihan-latihan untuk mengasah Bahasa Korea-mu. Dengan buku yang full colour dan banyaknya ilustrasi lucu, teman-teman akan sangat senang dan merasa bersemangat terus untuk belajar Bahasa Korea!",
            link: "https://shopee.co.id/Funtastic-Korean-i.7252083.7611992502?sp_atk=a2a84f90-d43f-4d33-8cc3-55e988b1dab1&xptdk=a2a84f90-d43f-4d33-8cc3-55e988b1dab1"
        },
        {
            title: "Hello Korean",
            image: Book3,
            description: "Setelah Dear Oppa dan Funtastic Korean, Borassaem hadir kembali dengan buku berjudul Hello, Korean! Di buku ini, kamu bisa belajar tentang tata Bahasa; penggunaan partikel; penggunaan kata hubung; dan lain-lain melalui contoh dan latihan yang menyenangkan. Buku ini cocok untuk kamu yang ingin fokus belajar tata Bahasa Korea dasar yang digunakan sehari-hari. Borassaem sendiri belajar Bahasa Korea secara otodidak. Dan dia ingin mengajakmu belajar Bahasa Korea dengan cara yang menyenangkan, sehingga buku ini pun dilengkapi dengan ilustrasi yang menggemaskan. Psst, kamu juga bisa lebih mengenal Borassaem melalui cerita-ceritanya di dalam buku ini!",
            link: "https://shopee.co.id/Hello-Korean-by-Borassaem-i.7252083.17607064324?xptdk=20f82213-5173-4a84-933e-167c09e52ed8"
        }
    ];
    

    
    // State to track the screen width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Update windowWidth when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

   
    const [activeBookIndex, setActiveBookIndex] = useState(null);

    const bookClicked = (index) => {
        setActiveBookIndex(index === activeBookIndex ? null : index);
    };

    return (
        <div className="container-buku-borassaem">
            <div className={`flex flex-col-reverse desktop:flex-row items-center justify-center w-9/12 desktop:w-6/12 ${windowWidth < 1920 && windowWidth >= 1280 ? 'px-20' : 'px-0'} mx-auto desktop:space-x-40`}>
                <div className="flex flex-col text-center desktop:text-left mobile:p-4">
                    <h1 className="font-jua text-section-title desktop:text-banner-title text-primary-p60">
                        BUKU BORASSAEM
                    </h1>
                    <h1 className="font-lexend text-[16px] text-primary-p60 leading-5">
                    Yuk naikin level belajar bahasa Koreamu dengan buku-buku luar biasa dari Borassaem ini! 📚🌟 Dilengkapi dengan halaman full-color dan contoh yang relate sama kehidupan sehari-harimu, pastinya seru deh! Ucapkan selamat tinggal pada buku bahasa Korea yang membosankan dan sambutlah level baru belajar yang mengasyikkan bersama  buku dari Borassaem ini!
                    </h1>
                </div>
                {
                    windowWidth >= 1280 ?
                    <img className="" src={BukubukuMid} alt='buku-buku' />
                    :
                    <img className="" src={Bukubuku} alt='buku-buku' />
                }
                
            </div>

            <div>
            {windowWidth >= 1280 ? ( // Render BookCarousel for desktop
                <div>
                    <BookCarousel />
                </div>
            ) : ( // Render book for mobile
            <div className="text-center p-4 m-2 mx-auto w-9/12">
                {books.map((book, index) => (
                    <div key={index}>
                        <img src={book.image} alt={`book ${index + 1}`} className="h-auto max-h-full object-contain mb-2" />
                        {activeBookIndex === index ? ( // Show additional information if the book is active
                            <div className="">
                                <h3 className="text-section-title font-jua font-bold text-primary-p50 leading-10 transition-fade">
                                    {book.title}
                                </h3>
                                <span className="text-[16px] font-lexend text-primary-p50 leading-5 flex items-start mt-1 flex-1 mb-2">
                                    {book.description}
                                </span>
                                <div className="mt-4 mb-4 pt-0">
                                    <a
                                        href={book.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="bg-secondary-s40 font-bold font-lexend text-[24px] text-primary-p50 px-6 py-2 border-4 border-primary-p50"
                                    >
                                        BELI DISINI
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="mt-4 mb-4 pt-0">
                                <button
                                    onClick={() => bookClicked(index)} // Toggle the active state for the clicked book
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="bg-secondary-s40 font-bold font-lexend text-[24px] text-primary-p50 px-6 py-2 border-4 border-primary-p50"
                                >
                                    {book.title}
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            )}
        </div>


            <Footbar/>
        </div>
    )
}

export default BukuBorassaem;