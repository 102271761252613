import React, { useEffect, useState } from "react";

//assets 
import Book1 from '../assets/book_dear_oppa.png';
import Book2 from '../assets/book_fantastic_korean.png';
import Book3 from '../assets/book_hello_korean.png';

const BookCarousel = () => {
    const images = [Book1, Book2, Book3];
    const names = [
        'Book1',
        'Book2',
        'Book3',
        ];
    const descriptions = {
        'Book1': [
            'Para pecinta Kpop, yang saking sukanya sama K-Pop Idol, pernah nggak sih ingin nulis surat untuk bias tersayang? Apalagi kalau pakai Bahasa Korea, pasti kemungkinan untuk di-notice lebih besar, kan? Nggak usah takut buat mulai belajar! Buku ini akan membantumu untuk mengungkapkan terima kasih, permintaan maaf, dukungan, dan harapanmu pada bias-mu. Dengan buku yang full colour dan banyaknya ilustrasi lucu, teman-teman akan merasa dengan buku ini, belajar Bahasa Korea adalah sebuah aktivitas yang sangat menyenangkan! (beli disini)'
        ],
        'Book2': [
            'Belajar Bahasa asing itu paling mudah dengan mempelajari Bahasa sehari-hari yang pasti digunakan, iya kan? Jika kamu ingin belajar Bahasa Korea dengan mudah, kali ini Borassaem mengajak kalian untuk memperkenalkan diri, jalan-jalan ke Korea, makan bersama gebetan, bahkan nonton konser bareng teman dengan Bahasa Korea! Funtastic Korean terdiri dari: dasar-dasar huruf Korea (Hangeul) dan penjelasannya, contoh percakapan sehari-hari dalam hangul, romanji, dan Bahasa Indonesia, serta tata Bahasa Korea dasar dan latihan-latihan untuk mengasah Bahasa Korea-mu. Dengan buku yang full colour dan banyaknya ilustrasi lucu, teman-teman akan sangat senang dan merasa bersemangat terus untuk belajar Bahasa Korea!'
        ],
        'Book3': [
            'Setelah Dear Oppa dan Funtastic Korean, Borassaem hadir kembali dengan buku berjudul Hello, Korean! Di buku ini, kamu bisa belajar tentang tata Bahasa; penggunaan partikel; penggunaan kata hubung; dan lain-lain melalui contoh dan latihan yang menyenangkan. Buku ini cocok untuk kamu yang ingin fokus belajar tata Bahasa Korea dasar yang digunakan sehari-hari. Borassaem sendiri belajar Bahasa Korea secara otodidak. Dan dia ingin mengajakmu belajar Bahasa Korea dengan cara yang menyenangkan, sehingga buku ini pun dilengkapi dengan ilustrasi yang menggemaskan. Psst, kamu juga bisa lebih mengenal Borassaem melalui cerita-ceritanya di dalam buku ini!'
        ]
        };

        const links = {
            'Book1': 'https://shopee.co.id/Buku-Pelajaran-Bahasa-Korea-Dear-Oppa-i.37345149.1957566489?sp_atk=bf2351e6-2968-4aa9-886d-d929266c5a85&xptdk=bf2351e6-2968-4aa9-886d-d929266c5a85',
            'Book2': 'https://shopee.co.id/Funtastic-Korean-i.7252083.7611992502?sp_atk=a2a84f90-d43f-4d33-8cc3-55e988b1dab1&xptdk=a2a84f90-d43f-4d33-8cc3-55e988b1dab1',
            'Book3': 'https://shopee.co.id/Hello-Korean-by-Borassaem-i.7252083.17607064324?xptdk=20f82213-5173-4a84-933e-167c09e52ed8',
        };

    const descriptionTitles = [
        'Dear Oppa',
        'Funtastic Korean',
        'Hello Korean',
        ];

    const [activeIndex, setActiveIndex] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);

    const handleBulletClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOut(true); // Start the fade out animation
            setTimeout(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFadeOut(false); // End the fade out animation after changing the index
            }, 1000); // Change the index after 1 second, matching the duration of the fade out animation
        }, 5000); // Interval to change the index every 2 seconds
    
        return () => {
            clearInterval(interval);
        };
    }, [images.length]);

    return (
        <div className=" flex flex-none flex-col items-center justify-center">
            <div className="w-10/12 flex flex-col-reverse desktop:flex-row justify-center items-center space-y-8 desktop:space-y-0">
                <div className={`flex-none w-full desktop:w-1/2 ml-4 desktop:ml-16 ${fadeOut ? 'cross-fade' : ''}`}>
                    <div className={`desktop:h-[600px] max-h-[600px] overflow-hidden`}>
                        <div className="flex items-center justify-center desktop:h-full">
                            <img
                                src={images[activeIndex]}
                                alt="Staff Member"
                                className="h-auto max-h-full object-contain"
                            />
                        </div>
                    </div>
                </div>
                
                <div className={`flex-none w-full desktop:w-1/2 ml-4 desktop:ml-16`}>
                    <div className="flex flex-col min-h-[250px] max-h-[250px] mobile:mt-4 desktop:ml-12">
                        <h3 className="text-section-title desktop:text-page-title font-jua font-bold text-primary-p50 leading-10 transition-fade">
                        {descriptionTitles[activeIndex]}
                        </h3>

                        <div className="flex flex-col space-y-8">
                            <ul className="list-disc list-inside pt-4 desktop:w-11/12">
                                {descriptions[names[activeIndex]].map((desc, index) => (
                                    <span className="text-[16px] font-lexend text-primary-p50 leading-5 flex items-start mt-1 flex-1">{desc}</span>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="ml-12 mt-32 mb-0 pt-0">
                        <a
                            href={links[names[activeIndex]]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-secondary-s40 font-bold font-lexend text-[24px] text-primary-p50 px-6 py-2 border-4 border-primary-p50"
                        >
                            BELI DISINI
                        </a>
                    </div>
                    
                    <div className="flex ml-10 mobile:mt-0 mb-4">
                        {images.map((_, index) => (
                            <div
                                key={index}
                                className={`w-6 h-6 mr-4 my-8 desktop:mr-2 ml-2 desktop:mr-4 rounded-full border-4 border-primary-p60 mx-1 cursor-pointer ${
                                    activeIndex === index ? 'bg-accent' : 'bg-gray-400'
                                }`}
                                style={{
                                    transition: 'background-color 0.5s ease-in-out',
                                    backgroundColor: activeIndex === index ? '#FBBF24' : 'transparent',
                                }}
                                onClick={() => handleBulletClick(index)}
                            ></div>
                        ))}
                    </div>
                    
                </div>

                
            </div>
        </div>
    )
}

export default BookCarousel;