import React, {useState, useEffect } from "react";
import Footbar from "../components/Footbar";
import { Link } from "react-router-dom";
import classNames from "classnames";

//assets
import Banner from '../assets/splash_banner.png';
import BannerMobile from '../assets/splash_banner_mobile.png';
import Books from '../assets/book_toktok_class.png';
import Privat from '../assets/jenis_kelas_privat.png';
import Grup from '../assets/jenis_kelas_grup.png';
import Bisnis from '../assets/jenis_kelas_bisnis.png';

const JenisKelas = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isDesktop = windowWidth > 1279;

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    const containerClasses = classNames(
      isDesktop ? "container-jenis-kelas-desktop" : "container-jenis-kelas-mobile"
    );

    const contentData = [
        {
            title: "Privat",
            description: "Kegiatan belajar Bahasa Korea yang dilakukan secara intimate antara murid dengan Guru dengan tujuan Murid dapat lebih fokus dan berani dalam belajar dan berlatih Bahasa Korea. Jumlah peserta kelas Privat dibatasi 1 - 2 murid (online), dan maksimal 4 murid (offline).",
            image: Privat,
            reverse: false,
        },
        {
            title: "Grup",
            description: "Kegiatan belajar Bahasa Korea yang dilangsukan secara bersama-sama dengan 6 - 10 murid. Kelas ini cocok untuk para murid yang menyukai cara pembelajaran aktif dan kebersamaan. Kelas ini juga banyak diminati karena budget friendly.",
            image: Grup,
            reverse: true,
        },
        {
            title: "Bisnis (Kelas Khusus)",
            description: "Kegiatan belajar Bahasa Korea yang ditujukan untuk kerja sama dengan Perusahaan yang tertarik mengajarkan Bahasa Korea kepada para Staff, baik secara online maupun offline. Kelas ini akan fokus mengajarkan Bahasa Korea dengan ruang lingkup Bisnis yang bisa disesuaikan dengan hasil diskusi kebutuhan Perusahaan dengan TokTok Class.",
            image: Bisnis,
            reverse: false,
        },
    ];

    return (
        <div className={containerClasses}>
            <div className="flex flex-col tablet:flex-col-reverse desktop:flex-row items-center justify-center mx-auto">
                <div className="flex flex-col p-4 dekstop:p-8 text-center mx-auto">
                    <h1 className={`font-jua text-primary-p60 font-bold text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title 
                        ${windowWidth >= 1920 ? 
                        'w-7/12' : 
                        windowWidth < 1920 && windowWidth >= 1280 ?
                        'w-8/12' :
                        'w-12/12'
                        } mx-auto`}>JENIS KELAS YANG ADA DI TOKTOK CLASS</h1>
                    <h1 className="font-lexend text-primary-p60 text-[16px] desktop:text-[20px] w-10/12 desktop:w-9/12 mx-auto">TokTok Class memiliki beberapa jenis kelas yang tentunya disesuaikan dengan kebutuhan masing-masing Murid loh! Yuk scroll ke bawah dan cari tahu jenis kelas apa yang sesuai dengan kebutuhanmu?</h1>
                </div>
            </div>

            <div className="hidden desktop:flex items-center justify-center w-10/12 mx-auto space-x-32 desktop:mb-24">
                <img className={`
                    ${windowWidth < 1920 && windowWidth > 1400 ? 
                    "w-6/12" :
                    windowWidth <= 1400 && windowWidth >= 1280 ?  
                    "w-5/12" :
                    "w-12/12" 
                    }`} src={Books} alt='buku-buku' 
                />
                <div className="flex flex-col">
                    <h1 className={`font-jua 
                        ${windowWidth <= 1920 && windowWidth > 1730 ?
                        "text-[24px]" : 
                        windowWidth <= 1730 && windowWidth > 1600 ? 
                        "text-[22px]" :
                        windowWidth <= 1600 && windowWidth > 1500 ?
                        "text-[20px]" :
                        windowWidth <= 1500 && windowWidth >= 1280 ?
                        "text-[18px]" :
                        "text-section-title" //32px
                        } text-primary-p60 flex flex-row`
                        }>
                        Belajar bahasa korea dari 
                        <section className={`animation font-jua
                            ${windowWidth <= 1920 && windowWidth > 1730 ?
                            "text-[40px]" :
                            windowWidth <= 1730 && windowWidth > 1600 ?
                            "text-[38px]" :
                            windowWidth <= 1600 && windowWidth > 1500 ?  
                            "text-[36px]" :
                            windowWidth <= 1500 && windowWidth >= 1280 ?
                            "text-[34px]" :
                            "text-page-title" //48px
                            } text-primary-p50`
                        }>
                            <div className=""><div>DRAKOR</div></div>
                            <div className=""><div>K-POP</div></div>
                            <div className=""><div>NATIVE</div></div>
                        </section>
                        ?
                    </h1>
                    <h1 className={`font-jua
                         ${windowWidth <= 1920 && windowWidth > 1730 ?
                            "text-[40px]" :
                            windowWidth <= 1730 && windowWidth > 1600 ?
                            "text-[38px]" :
                            windowWidth <= 1600 && windowWidth > 1500 ?  
                            "text-[36px]" :
                            windowWidth <= 1500 && windowWidth >= 1280 ?
                            "text-[34px]" :
                            "text-page-title" //48px
                            } text-primary-p60 flex flex-row`}>
                        Semua <span className="text-primary-p50 px-4"> BISA</span> di TokTok Class
                    </h1>
                </div>
            </div>
            
            <div className="border-dotted border-t-2 border-primary-p50 w-12/12 desktop:w-10/12 desktop:mx-auto p-6 "/>

            <div className="flex flex-col space-y-12">
                {isDesktop ? 
                    (
                        contentData.map((content, index) => (
                            <div key={index} className={`flex ${content.reverse ? 'flex-row-reverse' : 'flex-row'} w-10/12 desktop:w-10/12 desktop:px-12 mx-auto`}>
                                <div className={`desktop:text-left mobile:text-center ${content.reverse ? 'pr-0' : 'pl-0'} p-4`}>
                                    <h1 className="font-jua text-primary-p60 text-section-title desktop:text-page-title"> {content.title === "Bisnis (Kelas Khusus)" ? (
                                    <>
                                        Bisnis <span className="text-[26px]">(Kelas Khusus)</span>
                                    </>
                                ) : (
                                    content.title
                                )}</h1>
                                    <span className="font-lexend text-primary-p60 text-[16px] desktop:text-[20px]">
                                        {content.description}
                                    </span>
                                </div>
                                <img src={content.image} alt={content.title} className="w-auto h-auto object-contain" />
                            </div>
                        ))
                    ) 
                    : 
                    (
                    // Render the content as a column for non-desktop screens
                    contentData.map((content, index) => (
                        <div key={index} className={`flex flex-col-reverse w-10/12 mx-auto`}>
                            <div className={`text-center p-4`}>
                                <h1 className="font-jua text-primary-p60 text-section-title desktop:text-page-title"> {content.title === "Bisnis (Kelas Khusus)" ? (
                                <>
                                    Bisnis <span className="text-[20px]">(Kelas Khusus)</span>
                                </>
                            ) : (
                                content.title
                            )}</h1>
                                <span className="font-lexend text-primary-p60 text-[16px] desktop:text-[20px]">
                                    {content.description}
                                </span>
                            </div>
                            <img src={content.image} alt={content.title} className="w-auto h-auto object-contain mx-auto" />
                        </div>
                    ))
                )}
            </div>

            <div className="bg-primary-p20 tablet:w-10/12 desktop:w-10/12 tablet:mx-auto desktop:mx-auto">
                <Link to="/daftar">
                    <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={Banner} alt="hero_image" />
                </Link>

                <Link to="/daftar">
                    <img  className={`mx-auto my-auto py-10 ${windowWidth > 719 ? 'hidden' : '' }`} src={BannerMobile} alt="hero_image" />
                </Link>
            </div>

            <Footbar/>
        </div>

        
    )
}

export default JenisKelas;