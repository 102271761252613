import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import FloatingWA from './components/FloatingWA';

// pages
import Home from './pages/Home';
import MediaSosial from './pages/MediaSosial';
import Testimoni from './pages/Testimoni';
import Faq from './pages/Faq';
import StaffPengajar from './pages/StaffPengajar';
import TokTokClass from './pages/TokTokClass';
import BukuBorassaem from './pages/BukuBorassaem';
import JenisKelas from './pages/JenisKelas';
import TingkatanKelas from './pages/TingkatanKelas';
import DaftarSekarang from './pages/DaftarSekarang';

function App() {
  return (
    <div className="App">
     
      <Router>
        <NavBar/>
        <FloatingWA/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/tentangKami/tokTokClass" element={<TokTokClass />} />
          <Route path="/tentangKami/staffPengajar" element={<StaffPengajar />} />
          <Route path="/tentangKami/bukuBorassaem" element={<BukuBorassaem />} />
          <Route path="/kelas/jenisKelas" element={<JenisKelas />} />
          <Route path="/kelas/tingkatanKelas" element={<TingkatanKelas />} />
          <Route path="/testimoni" element={<Testimoni />} />
          <Route path="/mediaSosial" element={<MediaSosial />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/daftar" element={<DaftarSekarang />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
