import React from "react";
import Footbar from "../components/Footbar";

// assets 
import Content from '../assets/medsos_content.png';
import ContentMobile from '../assets/medsos_content_mobile.png';
import Yt from '../assets/logo_yt.png';
import Ig from '../assets/logo_ig.png';
import Tt from '../assets/logo_tt.png';

const MediaSosial = () => {

    const socialMediaLinks = [
        { icon: Yt, name: 'Borassaem', url: 'https://www.youtube.com/@BORASSAEM' },
        { icon: Ig, name: 'Borassaem', url: 'https://www.instagram.com/borassaem' },
        { icon: Ig, name: 'toktokclass', url: 'https://www.instagram.com/toktokclass' },
        { icon: Tt, name: 'borassaem', url: 'https://www.tiktok.com/@borassaem' },
      ];

    return (
        <div className="container-medsos">
            <img src={Content} alt="Content" className="hidden desktop:block" />
            <img src={ContentMobile} alt="Content" className="w-full block desktop:hidden " />
            <div className="flex flex-col desktop:flex-row justify-between w-11/12 desktop:w-10/12 mx-auto my-0 desktop:my-6 p-8">
                {socialMediaLinks.map((link, index) => (
                    <a key={index} href={link.url} target="_blank" rel="noopener noreferrer" className="flex flex-row bg-secondary-s40 border-4 border-primary-p60 mt-8 desktop:mt-0">
                        <img src={link.icon} alt={link.name} className="p-1 desktop:p-4 my-auto" />
                        <span className="font-jua text-[16px] desktop:text-section-title leading-10 text-primary-p60 px-4 desktop:px-2 p-0 desktop:p-2 my-auto">{link.name}</span>
                    </a>
                ))}
            </div>
            <Footbar/>
        </div>
    )
}

export default MediaSosial;