import React, { useState, useEffect } from "react";
import './Component.css';
//assets 
import Bora from '../assets/staff-borassaem.png';
import Yulin from '../assets/staff-yulin.png';
import Arin from '../assets/staff-arin.png';
import Ai from '../assets/staff-ai.png';
import Alfie from '../assets/staff-alfie.png';
import Febri from '../assets/staff-febri.png';

const StaffCarousel = () => {
    const images = [Bora, Yulin, Arin, Ai, Alfie, Febri]; // Replace with your image paths
    const names = [
    'Borassaem (보라쌤)',
    'Yulin Ssaem (율린 쌤)',
    'Arin Ssaem (아린 쌤)',
    'Ai Ssaem (아이쌤)',
    'Alfie Ssaem (알피 쌤)',
    'Febri Ssaem (페브리 쌤)'
    ];
    const descriptionTitles = [
    'Si K-Content Creator!',
    'Si Murid Berprestasi!',
    'Si Otodidak yang menjadi Penerjemah Profesional!',
    'Si Pintar, Ceria & Berprestasi!',
    'Si Ssaem Academic Specialist!',
    'Si Multilingual Ssaem!'
    ];
    const descriptions = {
    'Borassaem (보라쌤)': [
        'Sarjana Ilmu Komunikasi FISIP Universitas Indonesia - Cumlaude (2018).',
        'Mengajar Bahasa Korea di IG, TikTok & Youtube (lebih dari 1 juta followers!).',
        'Penulis 3 buku belajar Bahasa Korea.',
        'Aktif sebagai translator & interpreter di bidang profesional dan industri KPOP.'
    ],
    'Yulin Ssaem (율린 쌤)': [
        'Sarjana Bahasa dan Kebudayaan Korea Universitas Indonesia (2017)',
        'Peserta student exchange ke Universitas Kyung Hee (Korea)',
        'Aktif sebagai translator & interpreter di bidang profesional & Industri KPOP'
    ],
    'Arin Ssaem (아린 쌤)': [
        'Sarjana Ilmu Komunikasi FISIP Universitas Brawijaya (2019)',
        '2 tahun berpengalaman sebagai guru (lebih dari 100 murid!) Bahasa Korea',
        '4 tahun pengalaman sebagai translator & interpreter di berbagai perusahaan Korea',
        'Punya banyak teman Korea!'
    ],
    'Ai Ssaem (아이쌤)': [
        'Sarjana Pendidikan Bahasa Korea Universitas Pendidikan Indonesia (2021)',
        'Perwakilan Indonesia untuk "The 28th AKS Summer Program for International Students 2019" di Korea',
        'Mendapat Beasiswa TOPIK (NIIED) 2019 ke Korea ',
        'Peraih Level 6 (Level Tertinggi) dalam TOPIK 2'
    ],
    'Alfie Ssaem (알피 쌤)': [
        'Sarjana Bahasa dan Kebudayaan Korea Universitas Indonesia (2019) Cumlaude!',
        'Aktif penelitian & sedang S2 di Ilmu Linguistik Universitas Indonesia',
        '6 tahun mengajar Bahasa Korea untuk orang Indonesia (berbagai jenjang mulai dari SD, SMP, SMA, Kuliah, Umum)',
        'Mengajar Bahasa Indonesia untuk orang Korea',
        'Aktif sebagai translator & interpreter di bidang profesional & Industri KPOP'
    ],
    'Febri Ssaem (페브리 쌤)': [
        'Sarjana Pendidikan Bahasa Korea (Universitas Pendidikan Indonesia)',
        '2 tahun berpengalaman sebagai guru Bahasa Korea',
        'Multilingual ssaem (Mengajar Bahasa Korea menggunakan Bahasa Indonesia & Inggris!)'
    ]
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const [fadeOut, setFadeOut] = useState(false);

    const handleBulletClick = (index) => {
        setActiveIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeOut(true); // Start the fade out animation
            setTimeout(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFadeOut(false); // End the fade out animation after changing the index
            }, 1000); // Change the index after 1 second, matching the duration of the fade out animation
        }, 5000); // Interval to change the index every 2 seconds
    
        return () => {
            clearInterval(interval);
        };
    }, [images.length]);

 
    return (
        <div className="bg-neutral-white flex flex-none flex-col items-center justify-center">
            <div className="w-10/12 flex flex-col-reverse desktop:flex-row justify-center items-center space-y-8 desktop:space-y-0">
                <div className={`flex-none w-full desktop:w-1/2 ml-4 desktop:ml-16`}>
                    <div className="flex flex-col min-h-[250px] max-h-[250px] mt-4 desktop:mt-20 desktop:ml-12 items-center desktop:items-start mx-auto desktop:mx-0">
                        <h3 className="text-section-title desktop:text-page-title font-jua font-bold text-primary-p50 leading-10 transition-fade">
                            {names[activeIndex]}
                        </h3>
                        <p className="text-[16px] font-lexend font-bold text-primary-p50 leading-5 pt-4 transition-fade">
                            {descriptionTitles[activeIndex]}
                        </p>

                        <div className="flex flex-col space-y-8">
                            <ul className="list-disc list-inside pt-4 desktop:w-11/12">
                                {descriptions[names[activeIndex]].map((desc, index) => (
                                    <li key={index} className="text-[16px] font-lexend text-primary-p50 leading-5 flex items-start">
                                        <span className="text-[20px] mt-1 mr-2">•</span>
                                        <span className="mt-1 flex-1">{desc}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    
                    <div className="flex flex-row ml-0 desktop:ml-10 mt-40 desktop:mt-20 mb-4 items-center justify-center desktop:justify-start desktop:items-start mx-auto desktop:mx-0"> 
                    {images.map((_, index) => (
                        <div
                            key={index}
                            className={`w-4 h-4 mr-4 my-8 desktop:mr-2 ml-2 desktop:mr-4 rounded-full border-4 border-primary-p60 bg-gray-400 mx-1 cursor-pointer ${
                                activeIndex === index ? 'bg-yellow-500' : 'bg-gray-400'
                            }`}
                            style={{
                                backgroundColor: activeIndex === index ? 'bg-yellow-500' : 'bg-gray-400',
                                transition: 'background-color 0.5s ease-in-out'
                            }}
                            onClick={() => handleBulletClick(index)}
                        ></div>
                    ))}
                    </div>
                </div>

                <div className={`flex-none w-full desktop:w-1/2 ml-4 desktop:ml-16 ${fadeOut ? 'cross-fade' : ''}`}>
                    <div className={`desktop:h-[600px] max-h-[600px] overflow-hidden`}>
                        <div className="flex items-center justify-center desktop:h-full">
                            <img
                                src={images[activeIndex]}
                                alt="Staff Member"
                                className="h-auto max-h-full object-contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffCarousel;