import React, { useState, useEffect } from "react";

const Alasan = ({ text, flippedText, alasan, image, imageF, index }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);
    
    return (
      <div
        className={`bg-primary-p50 desktop:mr-4 mb-8 desktop:mb-0 flex flex-col justify-between h-[340px] desktop:h-[340px] w-full desktop:w-[400px] ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={`flip-container ${isHovered ? 'hovered' : ''}`}>
          <div className="flipper bg-secondary-s30">
            {/* If not hovered */}
            <div className={`front ${isHovered ? 'hidden' : 'block'}`}>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-between font-lexend text-primary-p50 w-full">
                  <p className="font-bold border-2 border-secondary-s40 bg-secondary-s40 w-10/12 p-2">{alasan}</p>
                  <p className="font-bold border-2 border-secondary-s40 bg-secondary-s40 w-2/12 p-2 pl-6 ml-4">X</p>
                </div>
              </div>

              <div className="bg-secondary-s30 px-4">
                <p className={`font-lexend font-bold text-primary-p50 text-header ${windowWidth < 9300 ? "pt-1" : "pt-2"} pl-2`}>{text}</p>
              </div>

              <div className="ml-44">
                {
                index === 0 
                ? 
                <img src={image} alt="image" className="ml-4"/>
                :
                index === 1 
                ? 
                <img src={image} alt="image" className=""/>
                :
                index === 3 
                ?
                <img src={image} alt="image" className="mt-20 pr-4"/>
                :
                <img src={image} alt="image" className=""/>
                }
                  
              </div>
              
            </div>

            {/* If hovered */}
            <div className={`back flex flex-col font-lexend text-primary-p50  ${isHovered ? 'block' : 'hidden'}`}>
              {
                index === 0 
                ?
                <div>
                  <p className="font-bold w-10/12 text-[20px] px-4 py-2">{flippedText}</p>
                  <img src={imageF} alt="image" className="mx-auto"/>
                </div>
                :
                index === 1
                ? 
                <div className="flex flex-row-reverse my-auto">
                  <p className="font-bold w-10/12 text-[20px] p-2">{flippedText}</p>
                  <img src={imageF} alt="image" className="p-2"/>
                </div>
                :
                index === 2
                ?
                <div className="flex flex-row p-4">
                  <p className="font-bold w-10/12 text-[20px]">{flippedText}</p>
                  <img src={imageF} alt="image" className="pt-20"/>
                </div>
                :
                <div className="flex flex-row px-4 py-2">
                  <p className="font-bold w-10/12 text-[20px]">{flippedText}</p>
                  <img src={imageF} alt="image" className="pt-32 object-contain"/>
                </div>
              }
            </div>
            
          </div>
        </div>
    </div>
    )
}

export default Alasan;