import React, { useState, useEffect } from "react";
import Footbar from "../components/Footbar";

//assets
import Banner from '../assets/splash_banner.png';
import { Link } from "react-router-dom";
import BannerMobile from '../assets/splash_banner_mobile.png';
import DropdownTK from "../components/DropdownTK";

const TingkatanKelas = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="container-tingkatan-kelas">
            <div className="flex flex-col mobile:flex-col-reverse desktop:flex-row items-center justify-center w-10/12 mx-auto">
                <div className="flex flex-col w-screen w-11/12 p-4 dekstop:p-8 text-center">
                    <h1 className="font-jua text-primary-p60 font-bold text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title-mid desktop2k:text-banner-title">TINGKATAN KELAS</h1>
                    <h1 className="w-10/12 desktop:w-12/12 font-lexend text-primary-p60 text-[16px] desktop2k:text-[20px] mx-auto">Sama halnya dengan Sekolah, TokTok Class juga punya tingkatan kelas loh dalam belajar Bahasa Korea! Yuk simak silabus pembelajaran di bawah ini, dan temukan tingkatan kelas yang tepat untukmu.</h1>
                </div>
            </div>

            <div className="mx-auto">
                <DropdownTK/>
            </div>


            <div className="bg-primary-p20 tablet:w-10/12 desktop:w-10/12 tablet:mx-auto desktop:mx-auto">
                <Link to="/daftar">
                    <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={Banner} alt="hero_image" />
                </Link>

                <Link to="/daftar">
                    <img  className={`mx-auto my-auto py-10 ${windowWidth > 719 ? 'hidden' : '' }`} src={BannerMobile} alt="hero_image" />
                </Link>
            </div>

            <Footbar/>
        </div>
    )
}

export default TingkatanKelas;