import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footbar from "../components/Footbar";
import Testimonial from "../components/Testimonial";

//assets 
import Women from '../assets/testimoni_women.png';
import Male1 from '../assets/pp_male_1.png';
import Male2 from '../assets/pp_male_2.png';
import Male3 from '../assets/pp_male_3.png';
import Male4 from '../assets/pp_male_4.png';
import Male5 from '../assets/pp_male_5.png';
import Male6 from '../assets/pp_male_6.png';
import Male7 from '../assets/pp_male_7.png';
import Female1 from '../assets/pp_female_1.png';
import Female2 from '../assets/pp_female_2.png';
import Female3 from '../assets/pp_female_3.png';
import Female4 from '../assets/pp_female_4.png';
import Female5 from '../assets/pp_female_5.png';
import Female6 from '../assets/pp_female_6.png';
import Female7 from '../assets/pp_female_7.png';
import Female8 from '../assets/pp_female_8.png';
import Female9 from '../assets/pp_female_9.png';
import Female10 from '../assets/pp_female_10.png';


const Testimoni = () => {

  const testimonials = [
    {
      name: "",
      kelas: "",
      avatar:"",
      testi: ""
    },
    {
      name: "Ni Putu Eka Putri Suryanti",
      kelas: "(Kelas Tata Bahasa 2)",
      avatar: Female1,
      testi: "SUMPAH KELAS TOKTOK SELALU SERU! Cara belajar dan penyampaiannya sangat mudah. Aku pribadi ngerasa lebih cepat paham ketika belajar di TokTok Class. Terima kasih banyak TokTok Class! "
    },
    {
      name: "Geraldy Tan",
      kelas: "(Kelas Tata Bahasa 3)",
      avatar: Male2,
      testi: "Belajar di TokTok Class sangat FUN karena bener-bener diajarin sampe mengerti. Selain itu, aku semakin paham karena sebelum lanjut materi dan selalu diulang/ direview pelajaran minggu lalu. Materinya bagus! bisa aku gunakan di kehidupan sehari2 juga. Daily conversation yang diajarin sangat relate & juga gurunya sangat sabar."
    },
    {
      name: "Intan Nur Hafizah",
      kelas: "(Kelas Tata Bahasa 2)",
      avatar: Female10,
      testi: "Seneng bangett, ssaemnya seru, detail, dan sabar banget ngajarinnya! Pembelajarannya juga mudah dipahami karena kita langsung praktek bikin kalimat gitu. Sekali lagi makasii banyakk, seneng bisa ikut kelas di TokTok Class!"
    },
    {
      name: "Damayra Kastini Hidayat",
      kelas: "(Kelas Tata Bahasa 2)",
      avatar: Female2,
      testi: "Belajar 7 kali pertemuan plus 1 pertemuan ngobrol sama native orang Korea langsung. Wahh gak pernah boring! Mudah dipahami banget materinya dan tentunya Ssaem nya seru! TOP banget deh, semua murid juga jadi pada aktif belajarnya.. 정말 감사합니다 TokTok Class dan aku jadi lebih paham untuk struktur buat kalimat Bahasa Korea."
    },
    {
      name: "Nor Chotimah Yulianti",
      kelas: "(Kelas Hangeul)",
      avatar: Female3,
      testi: "Aku ikut kelas Hangeul, dan penjelasan Ssaem mudah dipahami jadi cepat masuk dalam ingatan. Tidak monoton diselingi bercanda jadi suasana jadi seru! Cara mengajarnya bagus dan mudah dipahami, makasih ssaem.. sukses untuk TokTok class!"
    },
    {
      name: "Birgitta Listyamurti Dewi",
      kelas: "(Kelas Tata Bahasa 4)",
      avatar: Female6,
      testi: "Semakin tinggi level kelasnya semakin seru karena semakin banyak kosakata yang dipelajari. Jujur aku seneng banget soalnya setelah sampe di level 4 ini aku udah bisa banyak paham kalo denger idola lagi live atau nonton drama. Terus udah mulai berani untuk coba ngomong walaupun masih suka salah tapi ssaem dengan sabar bantu buat ingetin lagi. Pokoknya TokTok class 짱!!! 쌤들은 고맙고 사랑해요🫶"
    },
    {
      name: "Septiani Anggraeni",
      kelas: "(Kelas Tata Bahasa 1)",
      avatar: Female9,
      testi: "Terimakasih TokTok class terutama Yulin ssaem sudah mengajar saya dengan baik, tadinya takut belajar karena memang belum lancar membaca langsung nekat ikut kelas tata Bahasa, tapi untungnya ssaemnya ramah dan teman-temannya juga asyik! ketemu teman dengan latar belakang dan alasan belajar berbeda bahkan ada yang di luar negeri, seru banget! ^^"
    },
    {
      name: "AND",
      kelas: "",
      avatar: Male4,
      testi: "Aku ikut TokTok Class ini gegara ngeliat postingan Borassaem di youtube bagian komunitas. Terus awal les jujur aku takut banget jadi yang paling muda dan ga punya temen cowo gitu. Tapi ternyata ada yang seumuran ama aku yaaaa walaupun ga ada temen cowo sih. Menurut aku belajar di TokTok Class itu seru banget, Yulin ssaem juga ngejelasinnya bener bener jelas dan di ppt slidenya ada gambarnya gitu jadi kita ga ngerasa bosen saat belajar. Intinya aku ngasih rate 10/10 deh buat TokTok Class ^^ "
    },
    {
      name: "BLD",
      kelas: "",
      avatar: Male6,
      testi: "Masih selalu seruu dan menyenangkan!! Semakin banyak dan bervariasi jugaa yg dipelajarii.. contoh yang dikasih juga bener-bener yang biasa dilakukan sehari-hari jadi bisa lebih cepet nangkepnya.. pokoknya TokTok class 짱!!!"
    },
    {
      name: "MG",
      kelas: "",
      avatar: Female8,
      testi: "Seru banget, aku lanjut dari TB 1 sama Arin Ssaem juga. Menurut aku kurikulum lesnya tuh cukup gampang buat pemula, tapi juga kompleks karena latihannya sering nyampur semua lesson. Keren banget TokTok Class!"
    }
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
    setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
    window.removeEventListener("resize", handleResize);
    };
}, []);

      
  return (
    <div className="container-testimoni">
        <div className="flex items-center justify-center mx-auto">
            <img
                src={Women}
                alt="Women"
                className="h-auto max-h-full object-contain "
            />
        </div>

        
        <div className={`flex flex-col space-y-4 
          ${
            windowWidth >= 1920 ? 
            "max-h-[1300px]" 
            : 
            windowWidth < 1920 && windowWidth >= 1280 ? 
            "max-h-[1800px]" 
            : 
            windowWidth < 1280 && windowWidth >= 800 ? 
            "max-h-[2200px]" 
            :  
            "max-h-fit"  } flex-wrap w-12/12 items-center desktop:w-10/12 mx-auto my-8 text-justify`}>
              {testimonials.map((testimonial, index) => (
                  <Testimonial key={index} testimonial={testimonial} index={index} />
              ))}
        </div>

        {/* <button className="bg-secondary-s40 font-lexend font-bold leading-7 text-primary-p50 border-4 border-primary-p50 px-10 py-4 m-8 text-header flex items-center justify-center mx-auto mt-4 focus:outline-none shadow-md active:bg-primary-p60 active:text-neutral-white active:border-transparent transform active:scale-95">
            <Link to="#">
                Lihat lebih banyak
            </Link>
        </button> */}
        <Footbar/>
    </div>
  )
}

export default Testimoni;