import React, { useState, useEffect } from "react";
import Footbar from "../components/Footbar";
import { Link } from "react-router-dom";

//assets
import Daftar1 from '../assets/daftar_1.png';
import Daftar2 from '../assets/daftar_2.png';
import Daftar3 from '../assets/daftar_3.png';
import Daftar4 from '../assets/daftar_4.png';
import Daftar5 from '../assets/daftar_5.png';
import BannerMobile from '../assets/splash_banner_mobile.png';

const DaftarSekarang = () => {
    const items = [
        {
          number: 1,
          title: 'Daftar melalui WA',
          image: Daftar1,
          description: "Nomor WA +62 857 1454 9374 atau bisa menggunakan tombol MULAI PERCAKAPAN yang ada disebelah kanan bawah.",
        },
        {
          number: 2,
          title: 'Pilih kelas yang diinginkan',
          image: Daftar2,
          description: "Jenis kelas yang ada di TokTok class bisa dilihat di link ini.",
        },
        {
          number: 3,
          title: 'Mengikuti tes penempatan',
          image: Daftar3,
          description: "Tes penempatan bersifat kondisional / jika diperlukan. Kegunaan tes ini, supaya Murid bisa mengikuti pembelajaran yang sesuai dengan nyaman.",
        },
        {
          number: 4,
          title: 'Transfer biaya pendaftaran',
          image: Daftar4,
          description: "Biaya pendaftaran akan diinfokan melalui WA.",
        },
        {
          number: 5,
          title: 'Mengikuti kelas TokTok',
          image: Daftar5,
          description: "Welcome to TokTok class",
        },
      ];

      const [currentImageIndex, setCurrentImageIndex] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 4000); // Change image every 4 seconds

        return () => clearInterval(interval);
    }, [items.length]);

    return (
        <div className="bg-primary-p30">
            <div className="flex flex-col mobile:flex-col-reverse desktop:flex-row items-center justify-center w-10/12 mx-auto">
                <div className="flex flex-col w-screen desktop:w-11/12 p-4 dekstop:p-8 text-center">
                    <h1 className="font-jua text-primary-p60 font-bold text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title">CARA MENDAFTAR</h1>
                    <h1 className="font-lexend text-primary-p60 text-[16px] desktop:text-[20px] mx-auto">
                        Yuk coba perhatikan langkah-langkah pendaftaran di bawah ini! <br/>
                        Caranya mudah dan akan dibantu oleh Admin TokTok Class yang nice dan informatif loh. <br/>
                        Siap bisa Bahasa Korea? Yuk buruan gabung!
                    </h1>
                </div>
            </div>

            <div className="hidden desktop:flex flex-row w-10/12 mx-auto space-x-12 ">
                <div className="numbered-list p-6 w-10/12">
                    {items.map((item) => (
                    <div key={item.number} className="numbered-item mb-4">
                        <div className="flex flex-row">
                            <span className="font-jua text-primary-p50 text-section-title px-2 mx-2">
                                {item.number}
                            </span>
                            <div className="flex flex-col font-lexend text-primary-p50">
                                <p className="font-bold text-header pt-1">{item.title}</p>
                                <p className="text-[20px] text-neutral-black">{item.description}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>

                <div className="flex flex-col mt-10">
                    {items.map((item, index) => (
                        <span
                            key={index}
                            className={`h-[95px] w-[10px] ${
                                currentImageIndex === index ? 'bg-primary-p50' : 'bg-neutral-n20'
                            }`}
                        />
                    ))}
                </div>

                <div className="flex flex-col mx-auto my-auto">
                    {items.map((item, index) => (
                        <img
                            key={index}
                            src={item.image}
                            alt={`illustration${index + 1}`}
                            className={`transition-opacity duration-1000 ${
                                index === currentImageIndex ? 'opacity-100 h-auto' : 'opacity-0 h-0'
                            }`}
                            style={{ display: 'block' }}
                        />
                    ))}
                </div>
            </div>

            <div className="flex desktop:hidden flex-col">
                {items.map((item, index) => (
                    <div key={index} className="flex flex-col p-4 mb-4">
                        <img
                            src={item.image}
                            alt={`illustration${index + 1}`}
                        />
                        <div className="flex flex-col font-lexend text-primary-p50 text-center">
                                <p className="font-bold text-[20px]">{item.title}</p>
                                <p className="text-[16px] text-neutral-black">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div>
                <Link to="/testimoni">
                    <img  className="mx-auto my-auto py-10 block desktop:hidden" src={BannerMobile} alt="hero_image" />
                </Link>
            </div>
            
            <Footbar/>
        </div>
    )
}

export default DaftarSekarang;