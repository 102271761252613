import React, { useEffect, useState } from "react";

// assets
import Photo1 from '../assets/toktokclass_photo_1.png';
import Photo2 from '../assets/toktokclass_photo_2.png';
import Photo3 from '../assets/toktokclass_photo_3.png';
import Photo4 from '../assets/toktokclass_photo_4.png';
import Photo5 from '../assets/toktokclass_photo_5.png';
import Photo6 from '../assets/toktokclass_photo_6.png';
import Photo7 from '../assets/toktokclass_photo_7.png';
import Photo8 from '../assets/toktokclass_photo_8.png';

const photos = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7, Photo8];

const PhotoSlider = () => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
        }, 2000); // Interval to change the index every 2 seconds

        return () => {
            clearInterval(interval);
        };
    }, [photos.length]);

    return (
        <div className={`relative ${windowWidth < 480 ? "mb-64" : "mb-96"} `}>
            {photos.map((photo, index) => (
                <img
                    key={index}
                    src={photo}
                    alt={`photo ${index}`}
                    style={{
                        position: 'absolute',
                        zIndex: index === currentPhotoIndex ? 1 : 0,
                    }}
                />
            ))}
        </div>
    );
};

export default PhotoSlider;
