import React, { useState, useRef, useEffect } from "react";

const KelasItem = ({ title, isOpen, toggleFunction, contentLeft, contentRight }) => {
    const [leftHeights, setLeftHeights] = useState([]);
    const rightHeightRefs = useRef([]);

    useEffect(() => {
        if (isOpen) {
            const updatedLeftHeights = contentRight.map((item, index) => {
                if (rightHeightRefs.current[index]) {
                    return rightHeightRefs.current[index].offsetHeight;
                }
                return 0;
            });
            setLeftHeights(updatedLeftHeights);
        }
    }, [isOpen, contentRight]);

    const styleDivClosed = "border-4 border-primary-p50 bg-secondary-s30 m-2 flex flex-col w-10/12 ";
    const styleDivOpen = "border-4 border-primary-p50 bg-primary-p50 m-2 flex flex-col w-10/12";

    const styleBtnClosed = "font-jua text-section-title text-primary-p50 flex flex-row justify-between p-2";
    const styleBtnOpen = "font-jua text-section-title text-neutral-white flex flex-row justify-between p-2";

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isDesktop = windowWidth > 1279;

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
        
    }, []);
    const [openMateri, setOpenMateri] = useState(false);

    const toggleMateri = () => {
        setOpenMateri(!openMateri);
        console.log('open materi : ', openMateri);
    }

    return (
        <div className={`${isOpen ? styleDivOpen : styleDivClosed}`}>
            <button onClick={toggleFunction} className={`${isOpen ? styleBtnOpen : styleBtnClosed}`}>
                <h1 style={{padding: '10px 30px'}}>{title}</h1>
                <div className={`border-l-2 ${ !isOpen ? 'border-primary-p50' : 'border-neutral-white'}`} style={{margin: '-10px', padding: '20px 30px'}}>
                    <span>X</span>
                </div>
            </button>
            {isOpen && (
                <div className="flex flex-row justify-between bg-neutral-white">
                    {/* left content */}
                    {isDesktop && !openMateri ? (
                        <div className="border-r-2 border-primary-p50 font-lexend text-[16px] text-primary-p60 leading-7 py-8 space-y-4 w-6/12">
                        {contentLeft.map((item, index) => (
                            index !== contentLeft.length-1 ?
                            <div className="border-b-2 border-primary-p50 px-4 py-2" key={index} style={{ height: isDesktop ? leftHeights[index] : '' }}>
                                <p className="text-header font-bold px-4">{item.key}</p>
                                <p className="px-4">{item.value}</p>
                            </div>
                            :
                            <div className="" key={index} style={{ height: isDesktop ? leftHeights[index] : '' }}>
                                <p className="text-header font-bold px-4">{item.key}</p>
                                <p className="px-4">{item.value}</p>
                            </div>
                        ))}
                    </div>
                    )
                    :
                    !isDesktop && openMateri ?
                    (
                    <button className="w-1/5" onClick={ () => toggleMateri() }>
                        <span className="text-[36px] mt-1 mr-2 font-lexend text-primary-p50" >{'<'}</span>
                    </button>
                    )
                    :
                    (
                    <div className={`border-r-2 border-primary-p50 font-lexend text-[16px] text-primary-p60 leading-7 py-8 space-y-4 w-[90%]`}>
                        {contentLeft.map((item, index) => (
                            index !== contentLeft.length-1 
                                ?
                                <div className="border-b-2 w-full border-primary-p50 pb-2" key={index} style={{ height: isDesktop ? leftHeights[index] : '' }}>
                                <p className="text-header font-bold px-4">{item.key}</p>
                                <p className="px-4">{item.value}</p>
                            </div>
                            :
                            <div className="" key={index} style={{ height: isDesktop ? leftHeights[index] : '' }}>
                                <p className="text-header font-bold px-4">{item.key}</p>
                                <p className="px-4">{item.value}</p>
                            </div>
                            
                            
                        ))}
                    </div>
                    )
                    }

                    {/* right content */}
                    {isDesktop && !openMateri ? 
                        (<div className="font-lexend text-[16px] text-primary-p60 leading-7 py-8 space-y-4 w-6/12">
                            {contentRight.map((item, index) => (
                                index !== contentRight.length -1 ?
                                <div className="border-b-2 border-primary-p50 py-2 px-4"  key={index} ref={el => rightHeightRefs.current[index] = el}>
                                    <div className="text-header font-bold px-4">{item.key}</div>
                                    <ul>
                                        {item.value.map((value, idx) => (
                                            <li key={idx} className="text-[16px] font-lexend text-primary-p60 leading-5 flex items-start">
                                                <span className="text-[20px] mt-1 mr-2 px-4">•</span>
                                                <span className="mt-1 flex-1 px-4">{value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                :
                                <div className=""  key={index} ref={el => rightHeightRefs.current[index] = el}>
                                    <div className="text-header font-bold py-2 px-4">{item.key}</div>
                                    <ul>
                                        {item.value.map((value, idx) => (
                                            <li key={idx} className="text-[16px] font-lexend text-primary-p60 leading-5 flex items-start">
                                                <span className="text-[20px] mt-1 mr-2 px-4">•</span>
                                                <span className="mt-1 flex-1 px-4">{value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>)
                        : 
                        !isDesktop && openMateri ? 
                            (<div className="font-lexend text-[16px] text-primary-p60 leading-7 py-8 space-y-4 w-[78%] border-l-2 border-primary-p50">
                            {contentRight.map((item, index) => (
                                index !== contentRight.length -1 ?
                                <div className="border-b-2 w-full border-primary-p50 pb-2 px-4" key={index} ref={el => rightHeightRefs.current[index] = el}>
                                    <div className="text-header font-bold px-4">{item.key}</div>
                                    <ul>
                                        {item.value.map((value, idx) => (
                                            <li key={idx} className="text-[16px] font-lexend text-primary-p60 leading-5 flex items-start">
                                                <span className="text-[20px] mt-1 mr-2">•</span>
                                                <span className="mt-1 flex-1">{value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                :
                                <div className=" pb-2 px-4" key={index} ref={el => rightHeightRefs.current[index] = el}>
                                    <div className="text-header font-bold px-4">{item.key}</div>
                                    <ul>
                                        {item.value.map((value, idx) => (
                                            <li key={idx} className="text-[16px] font-lexend text-primary-p60 leading-5 flex items-start">
                                                <span className="text-[20px] mt-1 mr-2">•</span>
                                                <span className="mt-1 flex-1">{value}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>)
                        :
                        (
                        <button className="w-1/5" onClick={ () => toggleMateri() }>
                            <span className="text-[36px] mt-1 mr-2 font-lexend text-primary-p50" >{'>'}</span>
                        </button>
                        )
                    }
                </div>
            )
            }
        </div>
    );
};
  

const DropdownTK = () => {

const [kelasItems, setKelasItems] = useState([
    {
        title: 'Hangeul',
        isOpen: false,
        contentLeft: [
            {
                key: '',
                value: 'Proses belajar akan dilangsungkan secara online menggunakan aplikasi Zoom. Jadi pastikan gadget-mu sudah memiliki aplikasi Zoom pada saat pembelajaran dimulai ya! Tertarik untuk mengikuti kelas Offline? Yuk chat TokTok Class via WhatsApp.'
            }
        ],
        contentRight: [
        {
            key: 'Silabus kelas Hangeul',
            value: [
            'Perkenalan',
            'Huruf Vokal Tunggal',
            'Huruf Vokal Ganda',
            'Huruf Konsonan Tunggal',
            'Huruf Konsonan Ganda',
            'Perkenalan Batchim',
            'Aturan Batchim part I',
            'Aturan Batchim part II',
            'Latihan membaca kalimat Hangeul dengan situasional tertentu',
            'Latihan membaca kalimat Hangeul lewat percakapan & buku cerita'
            ]
        }
        ]
    },
    {
        title: 'Tata Bahasa',
        isOpen: false,
        contentLeft: [
            {
                key: 'Tata Bahasa 1',
                value: 'Level lanjutan setelah Murid lancar menulis dan membaca Hangeul. Sebelum mendaftar kelas ini, Murid diminta mengikuti tes kemampuan membaca Hangeul, dan hasil record audio dikirimkan lewat chat WhatsApp.'
            },
            {
                key: 'Tata Bahasa 2',
                value: 'Level lanjutan setelah Murid menguasai materi Tata Bahasa 1.'
            },
            {
                key: 'Tata Bahasa 3',
                value: 'Level lanjutan setelah Murid menguasai materi Tata Bahasa 2.'
            },
            {
                key: 'Tata Bahasa 4',
                value: 'Level lanjutan setelah Murid menguasai materi Tata Bahasa 3.'
            },
            {
                key: 'Tata Bahasa 5',
                value: 'Level lanjutan setelah Murid menguasai materi Tata Bahasa 4.'
            }
        ],
        contentRight: [
        {
            key: 'Silabus kelas TB 1',
            value: [
            'Salam & Perkenalan Diri',
            'Kalimat Sederhana',
            'Susunan Membuat Kalimat',
            'Partikel Subjek (-은/는)',
            'Akhiran kalimat (-예요/이에요 & -가 아니에요)',
            'Kata Tunjuk Tempat',
            'Kata Tunjuk Benda',
            'Kata Ganti Orang',
            'Kepemilikan',
            'Bentuk Masa Kini',
            'Partikel Objek (-을/를)',
            'Keterangan Waktu',
            'Partikel -에',
            'Keterangan Tempat',
            'Partikel -에서',
            'Partikel Subjek (-이/가)',
            'Bentuk Pertanyaan',
            'Latihan Percakapan',
            'Latihan Percakapan Bersama Native',
            'Perkenalan Budaya',
            'Sesi Tanya Jawab'
            ]
        },
        {
            key: 'Silabus kelas TB 2',
            value: [
            'Bentuk Masa Lampau',
            'Bentuk Masa Depan',
            'Pengecualian Bentuk ㅂ dan ㄷ',
            'Pengecualian Bentuk 르 dan ㅡ',
            'Angka Sino (Cina)',
            'Angka Asli (Korea)',
            'Bentuk Sopan / Formal',
            'Latihan Percakapan Bersama Native',
            'Perkenalan Budaya',
            'Sesi Tanya Jawab',
            ],
        },
        {
            key: 'Silabus kelas TB 3',
            value: [
            "Bentuk ‘dan’ (와/과, 랑/이랑, 하고)",
            "Bentuk 'juga' (도)",
            "Bentuk ‘karena' (-아서/어서/해서, -(으)니까, -기 때문에)",
            "Bentuk Keinginan (-고 싶다)",
            "Bentuk Keinginan/ Menanyakan Pendapat (-을래요, -을까요)",
            "Bentuk Negatif (안, -지 않다, 못, -지 못하다)",
            "Mulai dan Dari (-에서 -까지, -부터 -까지)",
            "Perkiraan Jumlah (-나/이나)",
            "Durasi Kegiatan (-동안)",
            "Bentuk ‘kalau’ (-면)",
            "Bentuk ‘sambil’ (-으면서)",
            "Bentuk 'atau' (-나/이나, -거나)",
            "Bentuk ‘sekarang’ (-고 있다, -중이다)",
            "Latihan Percakapan",
            "Latihan Percakapan Bersama Native",
            "Perkenalan Budaya",
            "Sesi Tanya Jawab"
            ]
        },
        {
            key: 'Silabus kelas TB 4',
            value: [
            "Posisi dan kata tunjuk arah",
            "Bentuk 'larangan' (-하지마, -(으)면 안되다)",
            "Bertanya dan petunjuk arah jalan",
            "Bentuk 'kemampuan/ kemahiran' (-(으)줄 알다/ 모르다, -(으)ㄹ수 있다/ 없다)",
            "Bentuk 'setelah' (-(으)ㄴ후에, -(으)ㄴ다음에, -고 나서)",
            "Bentuk 'sebelum' (-기 전에)",
            "Bentuk 'dari dan kepada' (-한테서, -에게서)",
            "Bentuk 'keharusan' (-아/ 어/ 야 하다/ 되다)",
            "Bentuk 'tapi' (-(으)ㄴ데, -(으)지만)",
            "Bentuk 'konfirmasi' (-지요, -잖아요)",
            "Bentuk 'penekanan pernyataan' (-거든요)",
            "Bentuk 'pengandaian' (-(으)ㄹ 것 같다, -처럼)",
            "Bentuk 'perbandingan' (-보다)",
            "Bentuk 'ajakan' (-(으)ㅂ시다)",
            "Bentuk 'permohonan' (-(으)세요, -아/어/해 주세요)",
            "Latihan Percakapan Bersama Native",
            "Perkenalan Budaya",
            "Sesi Tanya Jawab"
            ]
        },
        {
            key: 'Silabus kelas TB 5',
            value: [
            "Bentuk 'keinginan' 했으면 좋겠다",
            "Bentuk 'harapan' 기 바라다",
            "Bentuk 'hanya' 만, 밖에",
            "Bentuk 'pernah/ tidak pernah' (으)ㄴ 적이 있다/ 없다",
            "Bentuk 'pernah mencoba' 아/ 어/ 해 보다",
            "Bentuk 'demi' 을 위해서",
            "Bentuk 'kemampuan' 잘하다/ 잘 못하다",
            "Bentuk 'pergi/ datang untuk' 러 가다/ 오다",
            "Bentuk 'akan' 으려고",
            "Bentuk 'kalau ingin' 으려면",
            "Bentuk 'saat' ㄹ때",
            "Bentuk 'memutuskan' 기로 하다",
            "Bentuk 'langsung akan' 자마자",
            "Bentuk 'setiap' 마다/매",
            "Bentuk honorifik 세요",
            "Bentuk akhiran kalimat 네요",
            "Bentuk akhiran kalimat 군요",
            "Bentuk informal (반말)",
            "Latihan berbicara 말하기 연습",
            "Latihan Percakapan Bersama Native",
            "Perkenalan Budaya",
            "Sesi Tanya Jawab"
            ]
        },
    ]
    },
    {
        title: 'Conversation',
        isOpen: false,
        contentLeft: [
            {
                key: 'Conversation 1',
                value: 'Level lanjutan setelah Murid menguasai materi Tata Bahasa 1 - 5. Pembelajaran akan fokus pada keberanian dan ketepatan dalam berbicara Bahasa Korea, serta pengajaran pelafalan Bahasa Korea yang sesuai dengan Penutur Asli / Native.'
            },
            {
                key: 'Conversation 2',
                value: 'Level lanjutan setelah Murid menguasai materi Conversation I.'
            }
        ],
        contentRight: [
        {
            key: 'Silabus kelas Conversation 1',
            value: [
            'Percakapan perkenalan diri',
            'Percakapan situasi jual beli',
            'Percakapan situasi Restoran',
            'Mid test bersama Guru Kelas',
            'Percakapan aktivitas menonton film / Bioskop',
            'Percakapan memesan penginapan',
            'Percakapan berkaitan Transportasi',
            'Final test bersama Native Teacher*',
            '*Khusus jenis kelas Grup'
            ]
        },
        {
            key: 'Silabus kelas Conversation 2',
            value: [
            "Percakapan pekerjaan & interview",
            "Percakapan perjalanan di Bandara",
            "Percakapan situasi di Pesawat",
            "Mid test bersama Guru Kelas",
            "Percakapan aktivitas selama berwisata",
            "Percakapan tentang minat dan hobi",
            "Percakapan mengenal budaya pacaran",
            "Final test bersama Native Teacher*",
            "*Khusus jenis kelas Grup"
            ]
        }
        ]
    },
    {
        title: 'TOPIK',
        isOpen: false,
        contentLeft: [
            {
                key: 'TOPIK 1',
                value: 'Kelas ini ditujukan bagi para murid yang ingin mengikuti Ujian Kecakapan Bahasa Korea (TOPIK 1) atau seperti TOEFL versi Korea. Dengan guru yang berpengalaman bertahun-tahun dalam mengajar Bahasa Korea, murid terjamin untuk mendapat skor tinggi dalam ujian ini. (lihat lebih lengkap -> silabus)'
            }
        ],
        contentRight: [
        {
            key: 'Silabus TOPIK 1',
            value: [
            'Perkenalan Ujian TOPIK dan Fungsinya',
            'Identifikasi Tema & Kalimat Rumpang : Pertanyaan tentang tema kalimat dan pertanyaan kalimat rumpang',
            'Identifikasi Gambar: Memilih pilihan yang salah & benar',
            'Identifikasi Paragraf Pendek : berlatih memilih jawaban yang sesuai serta membuat kesimpulan',
            'Identifikasi Paragraf Rumpang : melengkapi paragraf dengan kata sambung dan menjawab pertanyaan sesuai informasi paragraf',
            'Penyusunan kalimat menjadi paragraf',
            'Mocking Test'
            ]
        }
        ]
    },
    ]);

const toggleKelas = (index) => {
    setKelasItems((prevKelas) =>
        prevKelas.map((kelas, i) => {
        return { ...kelas, isOpen: i === index ? !kelas.isOpen : false };
        })
    );
};

  return (
    <div className="flex flex-col items-center justify-between py-8">
        {kelasItems.map((kelas, index) => (
        <KelasItem
            key={index}
            title={kelas.title}
            isOpen={kelas.isOpen}
            toggleFunction={() => toggleKelas(index)}
            contentLeft={kelas.contentLeft}
            contentRight={kelas.contentRight}
        />
        ))}
    </div>
  );
};

export default DropdownTK;