import React, { useEffect, useState} from "react";
import Footbar from "../components/Footbar";
import DropdownFAQ from "../components/DropdownFAQ";

import { Link } from "react-router-dom";
import './Pages.css';

//assets
import Hero from '../assets/hero_home.png';
import HeroMobile from '../assets/hero_home_mobile.png';
import Banner from '../assets/splash_banner.png';
import BannerMobile from '../assets/splash_banner_mobile.png';
import Testimoni from "../components/Testimoni";
import Stars from "../assets/5_stars.png";
import Borassaem from "../assets/staff-borassaem.png";
import Sound from "../assets/sound_icon.png";
// import SoundC from "../assets/sound_icon_clicked.png";
import SoundC from "../assets/sound_icon_pause.png";
import SsaemVoice from "../assets/ssaem_voice.mp3";
import Alasan from "../components/Alasan";

import Before1 from "../assets/f_sulit_1.png";
import Before2 from "../assets/f_sulit_2.png";
import Before3 from "../assets/f_sulit_3.png";
import Before4 from "../assets/f_sulit_4.png";

import After1 from "../assets/sulit_1.png";
import After2 from "../assets/sulit_2.png";
import After3 from "../assets/sulit_3.png";
import After4 from "../assets/sulit_4.png";

const Home = () => {

    const dataTestimoni = [
        { 
            comment: 'SUMPAH KELAS TOKTOK SELALU SERU! Cara belajar dan penyampaiannya sangat mudah. Aku pribadi ngerasa lebih cepat paham ketika belajar di TokTok Class. Terima kasih banyak TokTok Class! ',
            name: 'Ni Putu Eka Putri Suryanti', 
            course: '(Kelas tata bahasa 2)'
        },
        { 
            comment: 'Seneng bangett, ssaemnya seru, detail, dan sabar banget ngajarinnya! Pembelajarannya juga mudah dipahami karena kita langsung praktek bikin kalimat gitu. Sekali lagi makasii banyakk, seneng bisa ikut kelas di TokTok Class!',
            name: 'Intan Nur Hafizah', 
            course: '(Kelas tata bahasa 2)'
        },
        { 
            comment: 'Belajar 7 kali pertemuan plus 1 pertemuan ngobrol sama native orang Korea langsung. Wahh gak pernah boring! Mudah dipahami banget materinya dan tentunya Ssaem nya seru! TOP banget deh, semua murid juga jadi pada aktif belajarnya.. 정말 감사합니다 Toktok Class dan aku jadi lebih paham untuk struktur buat kalimat Bahasa Korea.',
            name: 'Damayra Kastini Hidayat', 
            course: '(Kelas tata bahasa 1)'
        },
        { 
            comment: 'Aku ikut kelas Hangeul, dan penjelasan Ssaem mudah dipahami jadi cepat masuk dalam ingatan. Tidak monoton diselingi bercanda jadi suasana jadi seru! Cara mengajarnya bagus dan mudah dipahami, makasih ssaem.. sukses untuk Toktok class!',
            name: 'Nor Chotimah Yuliantiz', 
            course: '(Kelas hangeul)'
        },
    ]

    const dataAlasan = [
        { 
            text: '“Belajar Bahasa Korea susah banget, tata Bahasa dan hurufnya buat aku susah ngerti”',
            flippedText: 'Pembelajaran disampaikan dengan cara yang FUN dengan penggunaan sehari-hari dari Ssaem yang seru',
            alasan: 'Tingkat kesulitan',
            image: Before1,
            imageF: After1
        },
        { 
            text: '“Bahasa Korea kayaknya kurang berguna deh. Budayanya juga beda banget.”',
            flippedText: 'Membuka banyak peluang baru seperti untuk bekerja, pergi kuliah S1/S2, dan meningkatkan pendapatan',
            alasan: 'Kurang motivasi',
            image: Before2,
            imageF: After2
        },
        { 
            text: '“Ga perlu belajar Bahasa Korea, kan sudah ada Google translate dan subtitle!”',
            flippedText: 'Membuka banyak peluang baru seperti untuk bekerja, pergi kuliah S1/S2, dan meningkatkan pendapatan',
            alasan: 'Tidak merasa perlu',
            image: Before3,
            imageF: After3
        },
        { 
            text: '“Aku terlalu sibuk, tidak punya waktu untuk belajar Bahasa Korea”',
            flippedText: 'Sekarang ini sudah tidak harus pergi ke tempat khusus untuk belajar bahasa, bisa online dan diatur sesuai dengan ketersediaan kamu.',
            alasan: 'Waktu yang terbatas',
            image: Before4,
            imageF: After4
        },
    ]

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isDesktop = windowWidth > 1279;

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    const [imageClicked, setImageClicked] = useState(false);
    const audioRef = React.createRef();
    
    const soundClick = () => {
        if (!imageClicked) {
        setImageClicked(true);
        audioRef.current.play();
        }

        setTimeout(() => {
            setImageClicked(false);
        }, 2000);
    };


    return (
        <div >
            <div className="container-home text-neutral-black ">
               { windowWidth >= 1280 ? 
                    <h1 className="text-center font-jua text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title pt-8">
                        BELAJAR BAHASA KOREA<br/> MUDAH DAN SERU
                    </h1>
                    :
                    <h1 className="text-center font-jua text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title pt-8">
                        BELAJAR BAHASA KOREA MUDAH DAN SERU
                    </h1>
                }

                <div className="flex flex-col desktop:flex-row justify-between w-12/12 desktop:w-10/12 mx-auto mt-8 desktop:mt-0">
                    <div className="flex flex-col justify-around desktop:text-left text-center space-y-16 desktop:space-y-0">
                        <div className="">
                            <h1 className="font-jua text-navbar-title desktop:text-page-title">FUN LEARNING</h1>
                            <h2 className="font-lexend text-[20px] ">belajar yang menyenangkan</h2>
                        </div>

                        <div className="flex flex-col items-center">
                            <img className="w-[256px]" src={Stars} alt="stars"/>
                            <span className="font-lexend text-[16px] desktop2k:text-[20px]">5/5 rating dari alumnus</span>
                        </div>
                    </div>

                   { isDesktop && (
                    <div>
                        <div className="col-span-1 flex justify-center items-center">
                            <img className="mx-auto" src={Hero} alt="hero_image" />
                        </div>

                        <button className="absolute top-1/8 transform -translate-y-40 left-1/2 transform -translate-x-1/2 bg-secondary-s40 font-lexend font-bold leading-7 px-10 py-4 text-header text-primary-p60 border-2 border-primary-p60 flex items-center justify-center focus:outline-none shadow-md hover:bg-primary-p60 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                            <Link to="/daftar">
                            DAFTAR SEKARANG
                            </Link>
                        </button>
                    </div>
                   )}

                   { isDesktop ? (
                        <div className="flex flex-col justify-around">
                            <div className="text-right">
                                <h1 className="font-jua text-banner-title-mid desktop2k:text-banner-title">500+</h1>
                                <h2 className="font-lexend text-[20px]">alumnus</h2>
                            </div>

                            <div className="text-right">
                                <h1 className="font-jua desktop:text-[36px] desktop2k:text-page-title">NATIVE CLASS</h1>
                                <h2 className="font-lexend text-[20px]">belajar langsung dari Oppa & Eonni</h2>
                            </div>
                        </div>
                        ) 
                        :
                        <div className="mt-8">
                            <div className="text-center">
                                <h1 className="font-jua text-navbar-title">NATIVE CLASS</h1>
                                <h2 className="font-lexend text-[16px]">belajar langsung dari Oppa & Eonni</h2>
                            </div> 

                            <div className="">
                                <img className="w-full" src={HeroMobile} alt="hero_image" />
                            </div>
                            
                            <button className="absolute top-4/5 transform -translate-y-20 transform left-1/2 transform -translate-x-1/2 bg-secondary-s40 font-lexend font-bold px-2 py-2 text-[16px] text-primary-p60 border-2 border-primary-p60 flex items-center justify-center focus:outline-none shadow-md hover:bg-primary-p60 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                                <Link to="/daftar">
                                    DAFTAR SEKARANG
                                </Link>
                            </button>
                        </div>
                   }
                </div>
            </div>

            <div className="bg-primary-p50">
                <div className="flex flex-col items-center pt-12 pb-4 font-jua text-center px-4 desktop:px-0 desktop:text-normal text-section-title desktop:text-page-title text-neutral-white">
                    <h1>Apa yang membuatmu ragu belajar Bahasa Korea?</h1>
                </div>

                <div className={`grid 
                    ${
                        windowWidth >= 1920 ? 
                        "grid-cols-4 w-11/12 gap-x-20" :
                        windowWidth <= 1920 && windowWidth > 1600 ?
                        "grid-cols-2 w-6/12 gap-x-20" :
                        windowWidth <= 1600 && windowWidth >= 1280 ?
                        "grid-cols-2 w-9/12 gap-x-40" :
                        windowWidth < 1280 && windowWidth > 800 ?
                        "grid-cols-1 w-4/12 px-6" :
                        windowWidth <= 800 && windowWidth > 480 ?
                        "grid-cols-1 w-10/12 px-8" :
                        "grid-cols-1 w-12/12 px-8" 
                        } gap-y-8 mx-auto
                    `}>

                    {
                        dataAlasan.map((box, index) => (
                            <Alasan key={index} text={box.text} flippedText={box.flippedText} alasan={box.alasan} image={box.image} imageF={box.imageF} index={index}/>
                        ))
                    }
                
                </div>

                <div className="pt-8 pb-16">
                    <button className="font-lexend font-bold leading-7 px-8 py-2 text-header text-neutral-white border-2 border-neutral-white flex items-center justify-center mx-auto mt-4 focus:outline-none shadow-md hover:bg-primary-p60 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                        <Link to="/tentangKami/tokTokClass">
                            Pelajari lebih lanjut
                        </Link>
                    </button>
               </div>
                
            </div>

            <div className="container-home-ssaem flex flex-col desktop:flex-row">
                <div className={`flex flex-col w-12/12 desktop:w-8/12 mx-auto px-8 py-8 ${windowWidth < 1600 ? "text-center" : "text-left"}`}>
                    <div className={`flex flex-row space-y-2 desktop:space-y-0 ${windowWidth < 1600 ? "justify-center" : "justify-normal"}`}>
                        <img src={ imageClicked ? SoundC : Sound} alt="sound" className="object-contain" onClick={soundClick}/>
                        <h1 className="font-jua text-section-title desktop:text-page-title text-primary-p50 pl-4">Ssaem</h1>
                        <audio ref={audioRef}>
                            <source src={SsaemVoice} type="audio/mp3" />
                        </audio>
                    </div>

                    <div className="font-lexend text-primary-p50 text-[16px] desktop:text-[20px] py-8">
                        <h1 className="font-bold py-4">Noun (Kata benda)</h1>
                        <h1>“선생님 or 쌤 (ssaem) adalah singkatan dalam Bahasa Korea! Di Korea, kita tidak memanggil guru dengan namanya langsung, jadi kita langsung panggil dengan panggilan “Ssaem”.</h1>
                    </div>

                    <div className={`${windowWidth > 1601 ? 'hidden' : '' }`}>
                        <img src={Borassaem} alt="borassaem" className="object-cover h-full w-full"/>
                    </div>

                    <div className="font-lexend text-primary-p50 py-8">
                        <h1 className="font-bold text-navbar-title desktop:text-section-title">Borassaem</h1>
                        <h1 className="font-bold py-2 text-[16px] desktop:text-[20px]">Si K-Content Creator!</h1>
                        <ul className="list-disc pl-4 text-[16px] desktop:text-[20px]">
                            <li>Sarjana Ilmu Komunikasi FISIP Universitas Indonesia -Cumlaude (2018).</li>
                            <li>Mengajar Bahasa Korea di IG, TikTok & Youtube (lebih dari 1 juta followers!).</li>
                            <li>Penulis 3 buku belajar Bahasa Korea.</li>
                            <li>Aktif sebagai translator & interpreter di bidang profesional dan industri KPOP.</li>
                        </ul>      
                    </div>

                    <div className="font-lexend text-primary-p50">
                        <h1 className="text-[16px] desktop:text-[20px]">Borassaem adalah salah satu dari banyak pengajar yang terkualifikasi di TokTok class. Semuanya akan membantu mu untuk mengenal dan belajar bahas Korea sampai bisa.</h1>
                    </div>

                    <div className="pt-8 pb-16">
                        <button className="font-lexend font-bold leading-7 px-8 py-2 text-[20px] desktop:text-header text-primary-p50 border-2 border-primary-p60 mx-auto mt-4 focus:outline-none shadow-md hover:bg-primary-p50 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                            <Link to="/tentangKami/staffPengajar">
                                Lihat Ssaem lainnya
                            </Link>
                        </button>
                    </div>
                </div>

                <div className={`flex flex-col ml-4 mr-36 flex-grow ${windowWidth <= 1601 ? 'hidden' : ''} `}>
                    <img src={Borassaem} alt="borassaem" className="object-cover h-full w-full"/>
                </div>
            </div>

            <div className="bg-primary-p40">
                <div className="flex flex-col desktop:flex-row items-center justify-around py-8">
                    <h1 className="font-jua text-section-title desktop:text-page-title text-primary-p60 text-center">Testimoni teman-teman TokTok Class</h1>
                </div>
    
                <div className={`grid 
                    ${
                    windowWidth >= 1920 ? 
                    "grid-cols-4 w-10/12 gap-x-20" :
                    windowWidth <= 1920 && windowWidth > 1600 ?
                    "grid-cols-2 w-6/12 gap-x-20" :
                    windowWidth <= 1600 && windowWidth >= 1280 ?
                    "grid-cols-2 w-9/12 gap-x-40" :
                    windowWidth < 1280 && windowWidth > 800 ?
                    "grid-cols-2 w-10/12 gap-x-40" :
                    "grid-cols-1 w-10/12 " 
                    } gap-y-8 mx-auto text-justify
                `}>
                    {dataTestimoni.map((box, index) => (
                        <Testimoni key={index} comment={box.comment} name={box.name} course={box.course} />
                    ))}
                </div>
               
               <div className="pt-8 pb-16">
                    <button className="font-lexend font-bold leading-7 px-8 py-2 text-header text-primary-p60 border-2 border-primary-p60 flex items-center justify-center mx-auto mt-4 focus:outline-none shadow-md hover:bg-primary-p60 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                        <Link to="/testimoni">
                            Review lainnya
                        </Link>
                    </button>
               </div>
               
            </div>

            <div className="bg-primary-p20">
                <div className="flex flex-col desktop:flex-row items-center justify-between py-8 w-9/12 mx-auto">
                    <h1 className="font-jua text-section-title text-primary-p60">FAQ</h1>
                    <h2 className="font-jua text-[24] text-primary-p60">(Pertanyaan yang sering ditanyakan)</h2>
                </div>

                <DropdownFAQ/>
               
                <button className="bg-neutral-white font-lexend font-bold leading-7 px-8 py-2 text-header text-primary-p60 border-2 border-primary-p60 flex items-center justify-center mx-auto mt-4 focus:outline-none shadow-md hover:bg-primary-p60 hover:text-neutral-white hover:border-transparent transform hover:scale-95">
                    <Link to="/faq">
                        Lihat lebih banyak
                    </Link>
                </button>
                                    
            </div>

            <div className="bg-primary-p20 tablet:w-10/12 desktop:w-10/12 tablet:mx-auto desktop:mx-auto">
                <Link to="/daftar">
                    <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={Banner} alt="hero_image" />
                </Link>

                <Link to="/daftar">
                    <img  className={`mx-auto my-auto py-10 ${windowWidth > 719 ? 'hidden' : '' }`} src={BannerMobile} alt="hero_image" />
                </Link>
            </div>

            <Footbar/>
        </div>   
    )
}

export default Home;