import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

//component
import FooterNavBar from "./FooterNavBar";

// assets
import Logo from '../assets/toktok_logo.svg';


const NavBar = () => {
    const [tentangKamiOpen, setTentangKamiOpen] = useState(false);
    const [kelasOpen, setKelasOpen] = useState(false);
    const [hamburgerOpen, setHamburgerOpen] = useState(false);
    const genericHamburgerLine = `h-1 w-6 my-0.5 rounded-full bg-primary-p50 transition ease transform duration-300`;
    const location = useLocation();

    const toggleTentangKamiDropdown = () => {
        setTentangKamiOpen(!tentangKamiOpen);
        setKelasOpen(false);
    };

    const toggleKelasDropdown = () => {
        setKelasOpen(!kelasOpen);
        setTentangKamiOpen(false);
    };

    const hamburgerToggle = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <nav className="bg-secondary-s30 sticky top-0 z-50">
            <div className="flex flex-row items-center justify-between px-2 desktop:px-8 py-2 w-10/12 mx-auto ">

                <div className="class-hamburger-button block desktop:hidden">
                    <button className="flex flex-col h-12 w-12 justify-center items-center group" onClick={() => hamburgerToggle()}>
                        <div className={`${genericHamburgerLine} ${hamburgerOpen ? "-rotate-45 translate-y-3" : ""}`}/>
                        <div className={`${genericHamburgerLine} ${hamburgerOpen ? "rotate-45 translate-y-1" : ""} mb-1`}/>
                        <div className={`${genericHamburgerLine} ${hamburgerOpen ? "opacity-0 duration-500" : ""}`}/>
                        <div className={`${genericHamburgerLine} ${hamburgerOpen ? "opacity-0 duration-700" : ""}`}/>
                    </button>
                    
                    {hamburgerOpen && (
                        <div className={`absolute bg-secondary-s30 shadow-lg mt-4 h-screen w-screen left-0 transition-all transform ${hamburgerOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1'} duration-300`}>
                            {hamburgerOpen && !tentangKamiOpen && !kelasOpen && (
                                <div className="flex flex-col">
                                    <div className="pt-12">
                                        <a href="/" className="flex justify-center center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Home</a>
                                        <a href="#" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60"onClick={() => toggleTentangKamiDropdown()}>
                                            Tentang kami  <span className="ml-2">{'>'}</span>
                                        </a>
                                        <a href="#" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60" onClick={() => toggleKelasDropdown()}>
                                            Kelas <span className="ml-2">{'>'}</span>
                                        </a>
                                        <a href="/testimoni" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Testimoni</a>
                                        <a href="/mediaSosial" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Sosial Media</a>
                                        <a href="/faq" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">FAQ</a>
                                    </div>
                                
                                    <div className="fixed inset-x-0 bottom-20 pb-4">
                                        <FooterNavBar />
                                    </div>
                                </div>
                            )}

                            {tentangKamiOpen && (
                                <div className="flex flex-col h-screen justify-between">
                                    <div className="pt-12">
                                        <a href="#" className="flex justify-center center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60" onClick={() => toggleTentangKamiDropdown()}>
                                            Tentang kami <span className="ml-2">{'<'}</span>
                                        </a>
                                        <a href="/tentangKami/tokTokClass" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">TokTok Class</a>
                                        <a href="/tentangKami/staffPengajar" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Staff Pengajar</a>
                                        <a href="/tentangKami/bukuBorassaem" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Buku Borassaem</a>
                                    </div>
                                    <div className="fixed inset-x-0 bottom-20 pb-4">
                                        <FooterNavBar />
                                    </div>
                                </div>
                            )}

                            {kelasOpen && (
                                <div className="flex flex-col h-screen justify-between">
                                    <div className="pt-12">
                                        <a href="#" className="flex justify-center center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60" onClick={() => toggleKelasDropdown()}>
                                            Kelas <span className="ml-2">{'<'}</span>
                                        </a>
                                        <a href="/kelas/jenisKelas" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Jenis Kelas</a>
                                        <a href="/kelas/tingkatanKelas" className="flex justify-center block px-4 py-2 text-primary-p50 font-jua hover:text-primary-p60">Tingkatan Kelas</a>
                                    </div>
                                    <div className="fixed inset-x-0 bottom-20 pb-4">
                                        <FooterNavBar />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="class-logo">
                    <a href="/" >
                        <img src={Logo} className="h-50 object-fit pl-14 desktop:pl-0" alt="Toktok Logo" />
                    </a>
                </div>
                
                <div className="class-home-text font-jua text-navbar-title hidden desktop:block">
                    <a className={`${location.pathname === '/' ? 'bg-primary-p60 text-white px-4 py-4 ' : 'text-primary-p50'}`} href="/">Home</a>
                </div>
                
                <div className="class-submenu-tentang-kami hidden desktop:block">
                    <button onClick={toggleTentangKamiDropdown} className=" text-primary-p50 text-navbar-title font-jua inline-flex">
                        Tentang kami
                        <svg
                        className={`mt-2 ml-2 h-5 w-5 ${tentangKamiOpen ? 'transform rotate-45' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        {tentangKamiOpen ? (
                            <>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 4v16m8-8H4"
                            />
                            </>
                        ) : (
                            <>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                            </>
                        )}
                        </svg>
                    </button>
                    {tentangKamiOpen && (
                        <div className="absolute bg-secondary-s30 shadow-lg mt-4 mx-2 p-2 w-max">
                            <a href="/tentangKami/tokTokClass" className="block px-4 py-2 text-primary-p50 font-jua hover:bg-blue-500 hover:text-white">
                                TokTok Class
                            </a>
                            <a href="/tentangKami/staffPengajar" className="block px-4 py-2 text-primary-p50 font-jua hover:bg-blue-500 hover:text-white">
                                Staff Pengajar
                            </a>
                            <a href="/tentangKami/bukuBorassaem" className="block px-4 py-2 text-primary-p50 font-jua hover:bg-blue-500 hover:text-white">
                                Buku Borassaem
                            </a>
                        </div>
                    )}
                </div>

                <div className="class-submenu-kelas hidden desktop:block">
                    <button onClick={toggleKelasDropdown} className=" text-primary-p50 text-navbar-title font-jua inline-flex">
                        Kelas
                        <svg
                        className={`mt-2 ml-2 h-5 w-5 ${kelasOpen ? 'transform rotate-45' : ''}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        >
                        {kelasOpen ? (
                            <>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 4v16m8-8H4"
                            />
                            </>
                        ) : (
                            <>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                            </>
                        )}
                        </svg>
                    </button>
                    {kelasOpen && (
                        <div className="absolute bg-secondary-s30 shadow-lg mt-4 mx-2 py-2 w-max">
                            <a href="/kelas/jenisKelas" className="block px-4 py-2 text-primary-p50 font-jua hover:bg-blue-500 hover:text-white">
                                Jenis Kelas
                            </a>
                            <a href="/kelas/tingkatanKelas" className="block px-4 py-2 text-primary-p50 font-jua hover:bg-blue-500 hover:text-white">
                                Tingkatan Kelas
                            </a>
                        </div>
                    )}
                </div>
                
                <div className="class-testimoni-text font-jua text-navbar-title hidden desktop:block">
                    <a href="/testimoni" className={`${location.pathname === '/testimoni' ? 'bg-primary-p60 text-white px-4 py-4' : 'text-primary-p50'}`}>Testimoni</a>
                </div>

                <div className="class-media-sosial-text font-jua text-navbar-title hidden desktop:block">
                    <a href="/mediaSosial" className={`${location.pathname === '/mediaSosial' ? 'bg-primary-p60 text-white px-4 py-4' : 'text-primary-p50'}`}>Media Sosial</a>
                </div>

                <div className="class-faq-text font-jua text-navbar-title hidden desktop:block">
                    <a href="/faq" className={`${location.pathname === '/faq' ? 'bg-primary-p60 text-white px-4 py-4' : 'text-primary-p50'}`}>FAQ</a>
                </div>
                
                <div className="class-button-daftar bg-secondary-s40 border-2 border-primary-p50 px-4 desktop:px-6 py-1 desktop:py-2">
                    <a href="/daftar" className={`block font-lexend font-bold leading-7 text-primary-p50 desktop:text-navbar-title ${windowWidth > 1600 ? "" : "hidden"}`}>DAFTAR SEKARANG</a>
                    <a href="/daftar"className={`block font-lexend font-bold leading-7 text-primary-p50 text-lg desktop:text-navbar-title ${windowWidth <= 1600 ? "" : "hidden"}`}>DAFTAR</a>
                </div>


            </div>
        </nav>
    )
}

export default NavBar;