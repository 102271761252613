import React from "react";

//assets 
import Stars from '../assets/5_stars.png';

const Testimonial = ({ testimonial, index }) => {
    
    return (
        <div key={index} className={`w-11/12 bg-neutral-white max-w-[350px] m-2 ${index === 0 ? 'bg-primary-p50' : ''}`}>
            {index === 0 ? (
            <div className="border-4 border-neutral-white">
                <div className="flex flex-row justify-between border-b-4 border-neutral-white">
                <p className="font-lexend text-white p-4">CERITA TEMAN-TEMAN TOKTOK</p>
                <p className="font-lexend text-white p-4 border-l-4 border-neutral-white" style={{margin:"-2px"}}>X</p>
                </div>
                <div>
                <p className="font-jua text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title text-white text-center">TESTI MURID</p>
                <img src={Stars} alt="five_stars" className="mx-auto py-2" />
                </div>
            </div>
            ) : (
            <>
                <div className="p-6">
                <p className="font-lexend text-primary-p50">{testimonial.testi}</p>
                </div>
                <div className="flex flex-row bg-secondary-s40">
                <div className="border-2 border-secondary-s30">
                    <img src={testimonial.avatar} alt="profile_picture" className="w-18 object-contain py-4 px-2" />
                </div>
                <div className="border-secondary-s30 px-4 py-2 my-auto">
                    <p className="font-lexend text-[20px] font-bold text-primary-p50">{testimonial.name}</p>
                    <p className="font-lexend text-[16px] text-primary-p50">{testimonial.kelas}</p>
                </div>
                </div>
            </>
            )}
        </div>
    );
};

export default Testimonial;
