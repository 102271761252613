import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footbar from "../components/Footbar";
import StaffCarousel from "../components/StaffCarousel";

//assets 
import Banner from '../assets/splash_banner.png';
import BannerMobile from '../assets/splash_banner_mobile.png';


const StaffPengajar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="container-staff-pengajar">

            <div className="flex flex-col items-center justify-center relative h-screen/2 py-8">
                <h1 className="text-center font-jua leading-10 desktop:leading-normal text-primary-p60 text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title pt-8 px-4 desktop:px-32 desktop:w-10/12">
                    KENALAN DENGAN PARA SSAEM YANG ASIK & SERU
                </h1>
                <h1 className="text-center font-lexend leading-5 desktop:leading-8 text-primary-p60 text-[16px] desktop:text-[20px] pt-8 px-4 desktop:px-32 w-12/12 desktop:w-10/12">
                    Tak kenal maka tak sayang! Yuk lihat profil para Ssaem di TokTok Class.<br/> Bersama guru-guru profesional, TokTok Class memberikan pengalaman belajar Bahasa Korea yang seru dan menyenangkan!
                </h1>
            </div>
            
            <div className="">
                <StaffCarousel/>
            </div>
            

            <div className="bg-primary-p20 tablet:w-10/12 desktop:w-10/12 tablet:mx-auto desktop:mx-auto">
                <Link to="/daftar">
                    <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={Banner} alt="hero_image" />
                </Link>

                <Link to="/daftar">
                    <img  className={`mx-auto my-auto py-10 ${windowWidth > 719 ? 'hidden' : '' }`} src={BannerMobile} alt="hero_image" />
                </Link>
            </div>

            <Footbar/>
        </div>
    )
}

export default StaffPengajar;