import React, { useState, useEffect } from "react";
import Star from '../assets/star.png';

const Testimoni = ({ comment, name, course }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <div className={`bg-neutral-white desktop:mr-4  border-2 border-secondary-s40`}>
            <div className="bg-neutral-white p-4">
                <p className="font-lexend text-primary-p50 text-[16px] desktop:text-[20px] line-clamp-4">{comment}</p>    
            </div>

            <div className="flex flex-row justify-between border-2 border-secondary-s40 bg-secondary-s40 min-h-[90px]">
                <div className="flex flex-col p-2 font-lexend text-primary-p50 mx-auto my-auto ">
                    <p className="font-bold text-sm">{name}</p>
                    <p className="text-sm">{course}</p>
                </div>

                <div className={`grid grid-cols-2 border-l-2 border-secondary-s30 `}>
                    <p className="font-jua text-primary-p50 text-section-title mx-auto my-auto">5</p>
                    <img src={Star} alt="WhatsApp" className="w-fit h-fit mx-auto my-auto" />
                </div>
            </div>
        </div>
    )
}

export default Testimoni;