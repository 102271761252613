import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footbar from "../components/Footbar";

//assets 
import Banner from '../assets/splash_banner.png';
import BannerMobile from '../assets/splash_banner_mobile.png';
import ContentToktok from '../assets/content_toktok_class.png';
import ContentToktokMobile from '../assets/content_toktok_class_mobile.png';
import PhotoSlider from "../components/PhotoSlider";

const TokTokClass = () => {
    const reasons = [
        "Belajar bersama Guru yang berpengalaman di dunia bisnis & entertainment Korea",
        "Pembelajaran cepat, padat, dan disampaikan dengan FUN!",
        "Sesi kelas bersama Native*",
        "Grup WhatsApp kelas bersama guru",
        "Gratis konsultasi sebelum pendaftaran",
        "Diskon menarik di setiap batch pendaftaran",
        "Terbuka kesempatan untuk kolaborasi di bidang entertainment",
        "Kegiatan offline rutin untuk murid dan followers TokTok Class",
    ];

    const videoIds = [
        'x5mQWEphBLA?si=xWv707eVSr3j6DUN', 
        'aSoiCLllkkY?si=qlSQgxvTzCnurz9i', 
        'Hw0Kb1c8HXo?si=TA1S9ad4EytVfU_M', 
        'R1QNLVE5dfs?si=ta38JQuH_wte1WWR',
        'XSuSIKNl2v0?si=9UIkDHwFHaBupOUk'
    ];

    const [activeVideo, setActiveVideo] = useState(0);
    const handleBulletClick = (index) => {
        setActiveVideo(index);
        const videoFrame = document.getElementById('videoFrame');
        videoFrame.src = `https://www.youtube.com/embed/${videoIds[index]}`;
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => {
        window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="container-toktok-class">
            <div className={`flex ${windowWidth > 1454 ? "flex-row" : "flex-col-reverse"} items-center justify-center w-10/12 mx-auto`}>
                <div className={`flex flex-col ${windowWidth > 1453 ? "w-10/12 text-left" : "text-center w-12/12"} px-4 desktop:px-8`}>
                    <h1 className="font-jua text-primary-p60 font-bold text-[20px] desktop:text-[40px]">안녕하세요! 똑똑클래스 입니다!</h1>
                    <h1 className="font-jua text-primary-p60 font-bold text-section-title desktop:text-banner-title-mid desktop2k:text-banner-title">Halo! Kami adalah TokTok Class</h1>
                    <h1 className="font-lexend text-primary-p60 text-[16px] desktop:text-[20px]">TokTok Class adalah kursus Bahasa Korea untuk orang Indonesia, yang resmi didirikan pada tahun 2022 oleh Borassaem. Hingga saat ini, TokTok Class telah meluluskan lebih dari 500 murid, dan mengajarkan Bahasa Korea pada ratusan murid setiap bulannya.</h1>
                </div>

                <div className={`${
                    windowWidth >= 1280 ? 
                    "w-9/12 pb-48 pt-8 mx-auto" : 
                    windowWidth < 1280 && windowWidth >= 1130  ? 
                    "w-9/12 pb-60 pt-8" : 
                    windowWidth < 1130 && windowWidth >=980 ? 
                    "w-9/12 pb-36 pt-10" : 
                    windowWidth < 980 && windowWidth >=800 ? 
                    "w-9/12 pb-20 pt-8" :
                    windowWidth < 800 && windowWidth >=780 ? 
                    "w-9/12 pb-12 pt-8" :
                    windowWidth < 840 && windowWidth >=700 ? 
                    "w-9/12 pb-4 pt-8" :
                    "w-11/12 pt-8"} my-auto`}>
                    <PhotoSlider/>
                </div>
            </div>

            <div>
                <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={ContentToktok} alt="hero_image" />
                <img  className={`mx-auto my-auto py-10 ${windowWidth > 720 ? 'hidden' : '' }`} src={ContentToktokMobile} alt="hero_image" />
            </div>

            <div className="flex flex-col desktop:flex-row items-center justify-around space-x-0 desktop:space-x-28 w-12/12 desktop:w-10/12 mx-auto">
                <div className="flex flex-col items-left px-4">
                    <h1 className="font-jua text-primary-p60 mobile:text-center text-section-title desktop:text-page-title">Kenapa harus mendaftar di TokTok Class?</h1>
                    <ul className="list-disc list-inside mb-4">
                        {reasons.map((reason, index) => (
                            <li key={index} className="text-[16px] desktop:text-[20px] font-lexend text-primary-p50 leading-5 flex items-start">
                                <span className="m-2 inline-block w-5 h-5 border-4 border-primary-p50 bg-accent rounded-full flex-shrink-0"></span> 
                                <span className="m-2">{reason}</span>
                            </li>
                        ))}
                    </ul>
                    <span className="font-lexend text-primary-p50 text-[16px] px-2">
                    *Khusus jenis kelas Grup mulai dari Tata Bahasa 1
                    </span>
                </div>

                <div className="flex flex-col items-center p-4">
                    <div className="relative w-[360px] h-[350px] mb-4">
                        <iframe
                            id="videoFrame"
                            className="absolute w-full h-full"
                            src={`https://www.youtube.com/embed/${videoIds[activeVideo]}`}
                            allowFullScreen
                        ></iframe>
                    </div>

                    <div className="flex">
                        {videoIds.map((_, index) => (
                            <span
                            key={index}
                            className={`bullet inline-block w-6 h-6 mx-2 border-4 border-primary-p60 rounded-full ${
                                index === activeVideo ? 'bg-yellow-500' : 'bg-gray-300'
                            }`}
                            onClick={() => handleBulletClick(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-primary-p20 tablet:w-10/12 desktop:w-10/12 tablet:mx-auto desktop:mx-auto">
                <Link to="/daftar">
                    <img className={`mx-auto my-auto py-10 ${windowWidth < 720 ? 'hidden' : '' }`} src={Banner} alt="hero_image" />
                </Link>

                <Link to="/daftar">
                    <img  className={`mx-auto my-auto py-10 ${windowWidth > 719 ? 'hidden' : '' }`} src={BannerMobile} alt="hero_image" />
                </Link>
            </div>
            
            <Footbar/>
        </div>
    )
}

export default TokTokClass;